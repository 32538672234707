

import { LitElement, html, css } from 'lit-element';

class InputCode extends LitElement {
  static get properties() {
    return {
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }

      .container {
        display: inline-flex;
        justify-content: left;
        gap: 10px;
      }

      .container sl-input::part(base) {
        width:50px;
      }
    `;
  }

  setFocus() {
    const first = this.shadowRoot.getElementById('code1');
    first && first.focus();
  }

  handlePaste(e) {
    e.preventDefault();
    let text = e.clipboardData.getData('text').replace(/\D/g, ''); // remove non-digits
    if (text.length >= 4) {
      this.shadowRoot.getElementById('code1').value = text.charAt(0);
      this.shadowRoot.getElementById('code2').value = text.charAt(1);
      this.shadowRoot.getElementById('code3').value = text.charAt(2);
      this.shadowRoot.getElementById('code4').value = text.charAt(3);
      this.emitEvent();
    }
  }

  emitEvent() {
    const value = this.getValue();
    if (value.length === 4) {
      this.dispatchEvent(new CustomEvent('change', { detail: { value } }));
    } else {
      this.dispatchEvent(new CustomEvent('change', { detail: { value:undefined } }));
    }
  }

  handleInput(e) {
    if (e.target.value.length === 1) {
      const nextInput = this.shadowRoot.getElementById(`code${parseInt(e.target.id.charAt(4)) + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
    
    this.emitEvent();
  }

  handleInputCodeChange() {
    // Votre code ici
  }

  getValue() {
    let value = '';
    for (let i = 1; i <= 4; i++) {
      value += this.shadowRoot.getElementById(`code${i}`).value;
    }
    return value;
  }

  render() {
    return html`
      <div class="container">
        <sl-input
          id="code1" 
          type="number" 
          no-spin-buttons 
          min="0" 
          max="9" 
          minlength="1" 
          maxlength="1" 
          required 
          @paste="${this.handlePaste}" 
          @input="${this.handleInput}">
        </sl-input>

        <sl-input
          id="code2" 
          type="number" 
          no-spin-buttons 
          min="0" 
          max="9" 
          minlength="1" 
          maxlength="1" 
          required 
          @paste="${this.handlePaste}" 
          @input="${this.handleInput}">
        </sl-input>

        <sl-input
          id="code3" 
          type="number" 
          no-spin-buttons 
          min="0" 
          max="9" 
          minlength="1" 
          maxlength="1" 
          required 
          @paste="${this.handlePaste}" 
          @input="${this.handleInput}">
        </sl-input>
        <sl-input
          id="code4" 
          type="number" 
          no-spin-buttons 
          min="0" 
          max="9" 
          minlength="1" 
          maxlength="1" 
          required 
          @paste="${this.handlePaste}" 
          @input="${this.handleInput}">
        </sl-input>
      </div>
    `;
  }
}

customElements.define('input-code', InputCode);
