import { LitElement, html, css } from 'lit-element';
import './IconMaterialSymbols';
import { sessionService } from '../Session'; 

class SessionIcon extends LitElement {
  static get properties() {
    return {
      logged: { type: Boolean },
    };
  }
  
  static get styles() {
    return css`
      :host {
        display: block;
        margin-right:10px;
        --size:33px;
      }

      sl-button.round::part(base) {
        border-radius:50%;
        height:var(--size);
        width:var(--size);
      }

      sl-button.round::part(label) {
        padding:0px;
        display:flex;
        justify-content:center;
        align-items:center;
      }

      m-icon {
        font-size: 33px;
      }

      sl-card::part(body) {
        width:170px;
        display:flex;
        flex-direction:column;
        align-items:center;
        gap:10px;
        margin:20px !important;
        margin-top:0px;
        padding:0px;
      }

      .big {
        font-size:50px;
        border-radius:50%;
        margin:0px;
        padding:0px;
      }

      .identity {
        font-size:12px;
        margin:0px;
        padding:0px;
      }

      .white {
        color:white;
      }
    `;
  }

  constructor() {
    super();
    this.logged = false;
    this.resizeTimeout = null;
    this.handleResize = this.handleResize.bind(this);
  }

  async connectedCallback() {
    super.connectedCallback();

    window.addEventListener('resize', this.handleResize);

    this.session = await sessionService.getUserSession();
    if (!this.session) return;
    if (this.session.email) {
      //this.emailFirstLetter = session.email.charAt(0).toUpperCase();
      this.logged = true;
    }
  }

  handleResize() {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => this.requestUpdate(), 200);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('resize', this.handleResize);
  }

  handleLoginClick() {
  const currentPort = window.location.port;
  let url = '/auth/loginr';
    if (currentPort !== '80' && currentPort !== '443') {
      url += '?p='+currentPort;
    }
    window.location.href = url;
  }

  handleAccountClick() {
    window.open(SDIO_KC_PUBLIC_URL+'/realms/platform/account/#/personal-info');
    this.hideDropdown();
  }

  hideDropdown() {
    this.dropdown = this.dropdown || this.shadowRoot.querySelector('sl-dropdown');
    this.dropdown.hide();
  }

  hideDialog() {
  }

  handleLogout() {
    this.hideDropdown();
    this.dialog = this.dialog || this.shadowRoot.querySelector('sl-dialog');
    this.dialog.show();
  }

  handleConfirmLogout() {
    let url = SDIO_KC_PUBLIC_URL;
    url+= '/realms/platform/protocol/openid-connect/logout';
    url+= '?post_logout_redirect_uri='+encodeURIComponent(SDIO_HOME_URL);
    
    const currentPort = window.location.port;
    if (currentPort.length) {
      url += ':' + currentPort;
    }

    url+='/auth/logout';

    url+= '&id_token_hint='+this.session.ith;
    window.location.href = url;
  }


  render() {

    if (typeof this.session === undefined) {
      return;
    }

    const btLoginContent = window.innerWidth > 660 
      ? html`<sl-button size="small" title="Se connecter" pill @click="${this.handleLoginClick}">Connexion</sl-button>` 
      : html`<sl-button size="small" title="Se connecter" variant="text" pill @click="${this.handleLoginClick}" class="round"><m-icon class="white" name="account_circle"></m-icon></sl-button>`;


    return html`
      ${this.logged
        ? html`
          <sl-dropdown>
            <m-icon slot="trigger" clickable name="account_circle" class="white round"></m-icon>
            <sl-card>
              <m-icon name="account_circle" class="big"></m-icon>
              <div class="identity">${this.session.firstname} ${this.session.lastname}</div>
              <div class="identity">${this.session.email}</div>
              <sl-button size="small" variant="text" @click="${this.handleAccountClick}">Mon compte</sl-button>
              <sl-button size="small" variant="primary" @click="${this.handleLogout}">Se déconnecter</sl-button>
            </sl-card>
          </sl-dropdown>
          <sl-dialog label="Confirmation">
            <p>Êtes-vous sûr de vouloir vous déconnecter ?</p>
            <sl-button slot="footer" variant="primary" id="btlogout" @click="${this.handleConfirmLogout}">Oui, me déconnecter</sl-button>
          </sl-dialog>
        `
        : html`${btLoginContent}`
      }
    `;
  }
}

customElements.define('session-icon', SessionIcon);