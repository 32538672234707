import { LitElement, html, css } from 'lit-element';

import '@shoelace-style/shoelace/dist/components/input/input.js';
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown.js';
import '@shoelace-style/shoelace/dist/components/menu/menu.js';
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item.js';
import '@shoelace-style/shoelace/dist/components/tag/tag.js';
import '@shoelace-style/shoelace/dist/components/checkbox/checkbox.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';

import '@shoelace-style/shoelace/dist/components/icon/library.default.js';
import '@shoelace-style/shoelace/dist/utilities/icon-library.js';
import './sd-tag';
import db from'./sd-tags-db';

const LS_TAGS_KEY_DEFAULT = 'sd-tags';

class SdTagsPanel extends LitElement {
  static get styles() {
    return css`
      :host {
        position:relative;
        display:block;
        width:100%;
      }

      input {
        display:none;
      }
      
      sl-icon[slot="suffix"] {
        color: var(--sl-color-white-500);
        width: 23px;
        margin-right: 3px;
      }

      sl-input::part(base) {
        /*
        border: 0px;
        border-radius:0px;
        border-bottom:1px solid #AAAAAA22;
        background-color:transparent;
        */
      }

      .search_container sl-input {
        margin-left: 5px;
        margin-right: 5px;
        padding-right:0px;
      }

      icon-m[slot="suffix"] {
        font-size: 1.4em;
        margin-top:2px;
        margin-inline-end: 3px;
        color:var(--sl-input-icon-color);
      }

      .rotate {
        transform: rotate(-180deg);
      }

      sl-checkbox {
        display: block;
        width:100%;
        transition: background-color 0.3s ease;
      }

      sl-checkbox:hover::part(base) {
        box-shadow: inset 2px 0 0 0 var(--sl-color-primary-500) !important;
      }

      sl-checkbox::part(base) {
        display: flex;
        width: 100%;
        user-select: none;
        -webkit-user-select: none;
        font-size: 0.80em;
        padding-left:10px;
        line-height:25px;
        height:25px;
        align-items: center;
      }

      .tags {
        margin-inline-start: 4px;
        position:absolute;
        display:flex;
        z-index: 1;
      }

      sl-input.tags_input::part(input) {
        opacity: 0;
      }
      
      sd-tag {
        margin-right:4px;
        margin-bottom:1px;
        margin-top:1px;
        margin-left:1px;
      }
      
    `;
  }

  static get properties() {
    return {
      localStorageKey: { type: String },
      url: { type: String },
      query: { type: String }
    };
  }

  constructor() {
    super();
    this.tagsAvailable = [];
    this.query = '';
    this.previousSelectedOptions = [];
    this.loggingEnabled = true;

    this.log('constructor');
  }

  log(...messages) {
    if (this.loggingEnabled) {
      console.log(this.constructor.name, ...messages);
    }
  }
  
  updated(changedProperties) {
    this.log('updated', changedProperties);
    if (changedProperties.has('open') && this.open) {
      console.log('focus');
      this.shadowRoot.querySelector('.search_container sl-input').focus();
      this.loadSelectedTagsFromLocalStorage();
    }
  }

  onSearchInputClick(e) {
    e.stopPropagation();
  }

  onSearchInputChange(e) {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.query = this.searchInputTags.value;
      this.queryRe = new RegExp(this.searchInputTags.value, 'i');
    }, 300);
  }

  firstUpdated() {
    this.log('firstUpdated');

    this.onSearchInputClick = this.onSearchInputClick.bind(this);
    this.onSearchInputChange = this.onSearchInputChange.bind(this);

    this.searchInputTags = this.searchInputTags || this.shadowRoot.querySelector('.search_container sl-input');
    this.searchInputTags.addEventListener('click', this.onSearchInputClick);
    this.searchInputTags.addEventListener('input', this.onSearchInputChange);

    this.tagsAvailableContainer = this.tagsAvailableContainer || this.shadowRoot.querySelector('.tags');

  }

  resetTags() {
    const checkboxes = this.shadowRoot.querySelectorAll('sl-checkbox');
    checkboxes.forEach(checkbox => checkbox.checked = false );
    this.updateTags();
  }

  updateTags(selectedOptions, query) {
    const maxDisplayedTags = 5;
    let checkboxes = this.shadowRoot.querySelectorAll('sl-checkbox');
    selectedOptions = selectedOptions?.length ? selectedOptions : Array.from(checkboxes).filter(checkbox => checkbox.checked).map(checkbox => checkbox.value);
    
    // Check if selectedOptions has changed
    if (JSON.stringify(this.previousSelectedOptions) === JSON.stringify(selectedOptions)) {
      return;
    }

    this.previousSelectedOptions = selectedOptions;
    this.tagsAvailableContainer.innerHTML = '';

    //selectedOptions.slice(0, maxDisplayedTags).forEach(option => {
    selectedOptions.forEach(tagName => {
      const tag = document.createElement('sd-tag');
      tag.size='small';
      tag.textContent = tagName;
      tag.setAttribute('name', tagName);
      tag.setAttribute('removable',true);

      // prevent the click event from bubbling up to the sl-checkbox
      tag.addEventListener('click', e => {
        e.stopPropagation();
        if (!this.open) {
          this.open = true;
          this.shadowRoot.querySelector('.search_container sl-input').focus();
        }
      });

      // remove the tag when the user clicks the remove button
      tag.addEventListener('sl-remove', () => {
        this.tagsAvailableContainer.removeChild(tag);
        selectedOptions = selectedOptions.filter(tag => tag !== tagName);
        this.searchInput.value = selectedOptions.join(', ');
        checkboxes = this.shadowRoot.querySelectorAll('sl-checkbox');
        const correspondingCheckbox = Array.from(checkboxes).find(checkbox => checkbox.value === tagName);
        if (correspondingCheckbox) correspondingCheckbox.checked = false;
        this.updateTags();
      });

      // append the tag to the tags container
      this.tagsAvailableContainer.appendChild(tag);
    });

    if (maxDisplayedTags) {
      if (selectedOptions.length > maxDisplayedTags) {
        const tag = document.createElement('sd-button');
        tag.size = 'small';
        tag.textContent = `+${selectedOptions.length - maxDisplayedTags}`;
        this.tagsAvailableContainer.appendChild(tag);
      }
    }

    // Update the value of the sl-input
    this.searchInput.value = selectedOptions.join(', ');
    
    //const i = this.shadowRoot.querySelector('sl-input.tags_input::part(input)');
    const slInput = this.shadowRoot.querySelector('sl-input.tags_input');
    const input = slInput.shadowRoot.querySelector('input');
    if (!this.searchInput.value) {
      input.style.opacity = 1;
    } else {
      input.style.opacity = 0;
    }
    
    this.saveSelectedTagsToLocalStorage(selectedOptions);

    const changeEvent = new CustomEvent('sl-change', {
      detail: { value: this.searchInput.value },
      bubbles: true,
      composed: true
    });
    this.dispatchEvent(changeEvent);
  }

  async connectedCallback() {
    this.log('connectedCallback');
    super.connectedCallback();
    await this.loadTags();
    await this.loadSelectedTagsFromLocalStorage();
  }

  async loadTags() {
    const url = this.url || '/api/public/meta/tags?count=100';
    this.log('loadTags', url);
    const response = await fetch(url);
    const json = await response.json();
    this.log('loadTags', json.data);
    this.tagsAvailable = json.data;
    this.destroySpinner();
  }

  saveSelectedTagsToLocalStorage(selectedOptions) {
    localStorage.setItem(this.localStorageKey || LS_TAGS_KEY_DEFAULT, JSON.stringify(selectedOptions));
  }

  setSelected() {
    const selectedTags = JSON.parse(localStorage.getItem(this.localStorageKey || LS_TAGS_KEY_DEFAULT));
    if (selectedTags && this.shadowRoot) {
      const checkboxes = this.shadowRoot.querySelectorAll('sl-checkbox');
      checkboxes.forEach(checkbox => {
        if (checkbox.value && selectedTags.includes(checkbox.value)) {
          checkbox.checked = true;
        }
      });
    }
    return selectedTags;
  }

  updated() {
    this.setSelected();
  }

  async loadSelectedTagsFromLocalStorage() {
    this.log('loadSelectedTagsFromLocalStorage');
    clearTimeout(this.timerLoadSelectedTagsFromLocalStorage);
    this.timerLoadSelectedTagsFromLocalStorage = setTimeout(() => {
      const selectedTags =this.setSelected();
      this.updateTags(selectedTags);
    }, 100);
  }

  handleClearTagsChanges(ev) {
    this.preventOpen = true;
    this.open = false;
  }

  handleInputClick(e) {
    if (this.preventOpen) {
      this.preventOpen = false;
      return;
    }
    e.stopPropagation();
    if (!this.open) {
      this.open = true;
      console.log('focus');
      setTimeout(() => {
        this.shadowRoot.querySelector('.search_container sl-input').focus();
      }, 1);

    }
  }

  resetTagsSearch() {
    this.onSearchInputChange();
  }

  destroySpinner() {
    const spinner = this.shadowRoot.querySelector('sl-spinner');
    if (spinner) spinner.remove();
  }

  renderTags() {
    this.log('renderTags', this.tagsAvailable);
    return this.tagsAvailable.map(tag => {
      if (this.queryRe) {
        if (this.queryRe.test(tag.name)) {
          return html`<sl-checkbox value="${tag.name}"><sd-tag name="${tag.name}">${tag.name}</sd-tag></sl-checkbox>`
        }
        return '';
      }
      return html`<sl-checkbox value="${tag.name}"><sd-tag name="${tag.name}">${tag.name}</sd-tag></sl-checkbox>`
    });
  }

  render() {
    return html`
      <sl-spinner style="font-size: 2rem;"></sl-spinner>
      <div class="search_container">
        <sl-input size="small" placeholder="" clearable @sl-clear=${this.resetTagsSearch}>
          <icon-m slot="suffix" library="default" name="search"></icon-m>
        </sl-input>
        <div style="max-height: 40vh; overflow-y: auto;margin-top:5px;">${this.renderTags()}</div>  
      </div>
    `;
  }
}

customElements.define('sd-tags-panel', SdTagsPanel);