import { LitElement, html, css } from 'lit-element';
import { Router } from '@vaadin/router';
import pageStyles from '../../styles/page.js';
import stepsStyles from './styles.js';
import utils from './utils.js';
import Step from './Step.js';
import fetcher from '../../utils/fetcher.js';
import session from '../../utils/session.js';

class Step5 extends Step {
  static get styles() {
    return [
      pageStyles,
      stepsStyles,
      css`
        .align {
          display: flex;
          justify-content: flex-start;
          margin-bottom:10px;
        }

        .align sl-input {
          width:250px;
        }

        @media (max-width: 600px) {
          .align sl-input {
            width:150px;
          }
        }

        .align > * {
          margin-right:10px;
        }

        label {
          font-size:1.1em;
          line-height:2em;
          min-width:120px;
          white-space:nowrap;
          display:inline-block;
        }

        .wish {
          margin:10px;
          font-size:1.1em;
          margin-top:20px;
        }

        m-icon {
          color:var(--sl-color-neutral-400);
          cursor:pointer;
          line-height:30px;
        }

        m-icon:hover {
          color:var(--sl-color-neutral-600);
        }

        .bt_icon::part(base)   {
          height: 30px;
          min-height:initial;
          line-height:initial ;
        }

        .bt_icon::part(label) {
          margin:0px;
          padding:0px;
        }

        #loader {
          text-align:center;
        }

        #loader img {
          animation: rotate 5s linear infinite;
        }

        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        .error_dialog m-icon {
          color:red;
          font-size:50px;
        }

        .modal_dialog::part(header-actions) {
          display:none;
        }

        sl-dialog::part(title) {
          padding:10px;
        }

        sl-dialog::part(body) {
          padding:10px;
          overflow:hidden;
          min-height:140px;
        }

        .center {
          text-align:center;
        }

        .permalink {
          word-break: break-all;
          display:block;
          font-size : 0.8em;
        }

        sl-skeleton {
          margin-bottom: 1rem;
        }

        sl-skeleton.large {
          height:100px;
        }
      `
    ];
  }

  static get properties() {
    return {
    };
  }

  constructor() {
    super();
  }

  async connectedCallback() {
    super.connectedCallback();
    this.who = utils.getUrlParam('who', 'sysdream');
    this.wish = utils.getUrlParam('wish', 'dida');

    if (!utils.isWhoAllowed(this.who)) { Router.go('/public'); return; }
    if (!utils.isWishAllowed(this.wish)) { Router.go('/public'); return; }
  }

  async firstUpdated() {
    super.firstUpdated();
    this.btNext = this.shadowRoot.querySelector('#bt_next');

    this.sessionData = await session.get();
    if (
      !this.sessionData.email || 
      !this.sessionData.emailCode || 
      !this.sessionData.emailValidated ||
      !this.sessionData.ticketNumberHash
    ) {
      Router.go('/public/mondossier/etape4'+window.location.search);
      return;
    }

    this.updateHash();
  }

  updateHash() {
    setTimeout(() => {
      let hash = this.sessionData.ticketNumberHash;
      if (hash) {
        hash = encodeURIComponent(hash);
        this.localUrl = `/public/mondossier/consulter/${hash}?who=`+this.who;
        this.fullUrl = `${SDIO_HOME_URL}/public/mondossier/consulter/${hash}?who=`+this.who;
        Router.go(this.localUrl);
        return;
      }
      this.requestUpdate();
    }, 100);
  }

  goToDossier(e) {
    Router.go(this.localUrl);
  }

  render() {
    return html`
      <div class="content">
        <div class="background">
          <div class="item">
            <h3><img src="assets/img/dossier.png" class="img_icons"/>${document.title}</h3>
            <hr-custom></hr-custom>
            <div class="wish">
              ${!this.fullUrl ?
                html`` : 
                html`
                  <sl-button @click="${this.goToDossier}" pill variant="primary">
                    <m-icon slot="suffix" name="navigate_next"></m-icon>
                    Acceder à mon dossier
                  </sl-button>
                  <sl-copy-button value="${this.fullUrl}"></sl-copy-button>
                  <br/><br/>
                `
              }
            </div>
          </div>
        </div>
      </div>
    `;
  }

  goPreviousStep() {
    Router.go('/public/mondossier/etape3'+window.location.search);
  }

  goNextStep() {
    Router.go('/public/mondossier/etape5');
  }

}

customElements.define('page-dossier-etape5', Step5);
