const whos = [
  {
    value: 'sysdream',
    radioLabel: 'Je suis un client SysDream',
  },
  {
    value: 'cert-aviation-france',
    radioLabel:'Je suis adhérent au CERT Aviation France',
  }
]

const wishes = [
  {
    value: 'incass',
    radioLabel: 'Je souhaite déclarer un incident ou effectuer une demande d\'assistance pour une levée de doute'
  },
  {
    value: 'exo',
    radioLabel: 'Je participe à un exercice (Cyber Entraînement)'
  },
  {
    value: 'dida',
    radioLabel: 'Je souhaite consulter une déclaration d\'incident ou suivre ma demande d\'assistance'
  
  }
]


function getUrlParam(name, defaultValue) {
  const url = new URL(window.location.href);
  return url.searchParams.get(name) || defaultValue;
}

function setUrlParam(name, value) {
  const url = new URL(window.location.href);
  url.searchParams.set(name, value);
  
  // Récupérer l'URL précédente de l'état de l'historique
  const previousUrl = window.history.state ? window.history.state.url : null;

  // Remplacer l'URL seulement si elle est différente de la dernière URL poussée
  if (url.origin + url.pathname + url.search !== previousUrl) {
    window.history.replaceState({ url: url.origin + url.pathname + url.search }, '', url);
  }
}

function handlePageBackground() {
  const value = getUrlParam('who', 'sysdream');
  
  if (value === 'cert-aviation-france') {
    document.body.classList.add('caf_bg');
    window.dispatchEvent(new CustomEvent('web-particles-toggle', {detail: { show: false}}));
  } else {
    document.body.classList.remove('caf_bg');
    window.dispatchEvent(new CustomEvent('web-particles-toggle', {detail: { show: true}}));
  }
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function isWhoAllowed(value) {
  return whos.filter(who => who.value === value) ? true : false;
}

function isWishAllowed(value) {
  return wishes.filter(wish => wish.value === value) ? true : false;
}

function getWishTitle(id) {
  return wishes.filter(wish => wish.value === id)[0].radioLabel;
}

export default {
  getUrlParam,
  setUrlParam,
  handlePageBackground,
  sleep,
  isWhoAllowed,
  isWishAllowed,
  whos,
  wishes,
  getWishTitle
}