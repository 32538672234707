import './pages/home.js';
import './pages/cert.js';
import './pages/services.js';
import './pages/publications/avis.js';
import './pages/dossier/steps.js';
import './pages/notifications.js';
import './pages/user/logout.js';

function menuToRoutes(menu, parentPath  = '') {
  return Object.entries(menu).flatMap(([path, item]) => {
    const fullPath = parentPath+path;
    const route = { path: fullPath };
    if (item.title) route.title = item.title;
    if (item.component) route.component = item.component;
    if (item.redirect) route.redirect = item.redirect;
    route.animate = true;

    if (item.pages) {
      const childRoutes = menuToRoutes(item.pages, fullPath);
      return [route, ...childRoutes];
    } else {
      return [route];
    }
  });
}

const routesConfig = {
  '/public':                              { component: 'page-home',               title:'SysDream CSIRT/CERT' },
  '/public/cert':                         { component: 'page-cert',               title:'Le CERT SysDream' },
  '/public/services':                     { component: 'page-services',           title:'Services en ligne' },
  '/public/publications/avis':            { component: 'page-publications',       title:'Publications' },
  '/public/publications/avis':            { component: 'page-publication-avis',   title:'Avis sécurité SysDream' },
  '/public/mondossier/etape1':            { component: 'page-dossier-etape1',     title:'Mon dossier' },
  '/public/mondossier/etape2':            { component: 'page-dossier-etape2',     title:'Mon dossier' },
  '/public/mondossier/etape3':            { component: 'page-dossier-etape3',     title:'Mon dossier' },
  '/public/mondossier/etape4':            { component: 'page-dossier-etape4',     title:'Vérification de votre email' },
  '/public/mondossier/etape5':            { component: 'page-dossier-etape5',     title:'Accès à votre dossier autorisé' },
  '/public/mondossier/consulter/:hash':   { component: 'page-dossier-consulter',  title:'Votre dossier' },
  '/public/notifications':                { component: 'page-notifications',      title:'Notifications' },
  '/public/user/logout':                  { component: 'page-user-logout',        title:'Déconnexion' },
  '/private':                             { component: 'page-home',               title:'SysDream CSIRT/CERT' },
};

const routes = menuToRoutes(routesConfig);
routes.animate = true;

// redirect / to /public
routes.unshift({ path: '/', redirect: '/public' });

// handle 404
routes.push({ path: '(.*)', title: 'Page non trouvée', component: 'error-not-found' });

export default {
  basePath: '/',
  routes,
  localKeys:{
    theme:'theme'
  }
}

