import { LitElement, html, css } from 'lit-element';
import dayjs from 'dayjs';
import '@web-comp/core';
import '@web-comp/date-picker';

class SelectDateRange extends LitElement {
  static get styles() {
    return css`
      :host {
        width:300px;
      }

      :host * {
        margin-bottom:5px;
      }

      sl-select:first-child {
        margin-right: 10px;
      }

      sl-select:last-child {
        flex: 1 1 50%;
      }

      sl-option::part(base) {
        padding:0px;
        padding-left:3px;
        padding-top:3px;
        padding-bottom:3px;
      }

      sl-dialog::part(base) {
        font-size: var(--sl-font-size-medium);
        line-height: 10px;
        padding: 0px;
      }

      @media (max-width: 600px) {
        :host {
          flex-direction: column;
        }

        sl-select {
          margin-right: 0;
        }

        sl-select:first-child {
          margin-right: 0;
          margin-bottom: 5px;
          width: 100%;
        }

        sl-select:last-child {
          flex: 1 1 auto;
        }
      }

      sl-select::part(combobox) {
        border: 0px;
        border-bottom:1px solid #AAAAAA22;
        border-radius:0px;
        background-color:transparent;
      }
    `;
  }

  static get properties() {
    return {
      localStorageKey: { type: String }
    };
  }

  constructor() {
    super();
    this.period = localStorage.getItem(this.localStorageKey) || null;
    try {
      if (this.period) this.period = JSON.parse(this.period);
    } catch(e) {
      this.period = null;
    }

    this.allowedDates = [
      { value:null,      label:'Date indifférente' },
      { value:'1h',     label:'Moins d\'une heure' },
      { value:'1d',      label:'Moins de 24 heures' },
      { value:'1w',     label:'Moins d\'une semaine' },
      { value:'1m',    label:'Moins d\'un mois' },
      { value:'1y',     label:'Moins d\'un an' },
      { value:'range', label:'Dates précises' }
    ]
  }

  getDatesRange(period) {
    let start;
    let end = dayjs().toISOString();

    if (period === '1h') {
      start = dayjs().subtract(1, 'hour').toISOString();
    } else if (period === '1d') {
      start = dayjs().subtract(1, 'day').toISOString();
    } else if (period === '1w') {
      start = dayjs().subtract(7, 'day').toISOString();
    } else if (period === '1m') {
      start = dayjs().subtract(1, 'month').toISOString();
    } else if (period === '1y') {
      start = dayjs().subtract(12, 'month').toISOString();
    } else if (typeof id === 'object') {
      start = id.start;
      end = id.end;
    }


    if (start) {
      return { start, end };
    } else {
      return undefined;
    }
  }

  onDateRangeClick(e) {
    console.log(e);
  }

  formatDateRangeSelectorTitle(start, end) {
    this.popupDateRangeSelector.setAttribute('label', dayjs(start).format('DD/MM/YYYY') + ' - ' + dayjs(end).format('DD/MM/YYYY'));
  }

  closeDateRangeSelector() {
    this.popupDateRangeSelector.hide();
  }

  openDateRangeSelector() {
    // Créer un sl-dialog avec un wc-date-picker à l'intérieur
    this.popupDateRangeSelector = this.popupDateRangeSelector || document.createElement('sl-dialog');
    let start = new Date();
    let end = new Date();

    this.formatDateRangeSelectorTitle(start, end);
  
    this.popupDateRangeSelector.innerHTML = `
      <wc-date-picker
        switch="picker-switch"
        range="true"
        position="manual"
        start="${start}"
        end="${end}">
      </wc-date-picker>
      <sl-button slot="footer" variant="primary">Close</sl-button>
    `;

    // Ajouter le sl-dialog au body
    document.body.appendChild(this.popupDateRangeSelector);
    
    this.closeDateRangeSelector = this.closeDateRangeSelector.bind(this);
    this.popupDateRangeSelector.querySelector('sl-button').addEventListener('click', this.closeDateRangeSelector);

    // Ouvrir le sl-dialog
    this.popupDateRangeSelector.show();

    // Supprimer le sl-dialog du body lorsque l'utilisateur ferme le dialog
    this.popupDateRangeSelector.addEventListener('sl-after-hide', () => {
      this.popupDateRangeSelector.removeEventListener('date-click', this.onDateRangeClick);
      document.body.removeChild(this.popupDateRangeSelector);
    });

    this.onDateRangeClick = this.onDateRangeClick.bind(this);
    this.popupDateRangeSelector.addEventListener('date-click', this.onDateRangeClick);
  }

  handlePeriodChange(e) {
    e.preventDefault();
    e.stopPropagation();
    this.period = this.getDatesRange(e.target.value);
    if (e.target.value === 'range') {
      this.openDateRangeSelector();
      return;
    }

    if (this.period) {
      localStorage.setItem(this.localStorageKey, JSON.stringify(this.period));
    } else {
      localStorage.removeItem(this.localStorageKey);
    }

    const changeEvent = new CustomEvent('sl-change', {
      detail: { value: this.period },
      bubbles: true,
      composed: true
    });
    this.dispatchEvent(changeEvent);
  }

  render() {
    return html`
      <sl-select variant="text" placeholder="Dates" value="${this.period}" @sl-change="${e => this.handlePeriodChange(e)}" size="small" clearable>
        ${this.allowedDates.map(date => html`<sl-option size="small" value="${date.value}">${date.label}</sl-option>`)}
      </sl-select>
    `;
  }
}

customElements.define('select-date-range', SelectDateRange);