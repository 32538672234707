import { css } from 'lit-element';

export default css`
:host {
  width:100%;
}

search-bar, sl-breadcrumb, sl-select, sl-option, sl-popup {
  --sl-font-size-big: 10px;
  --sl-font-size-medium: 12px;
  --sl-font-size-small: 12px;
  --sl-input-font-size-small: var(--sl-font-size-small);
}


.content {
  max-width:796px;
  margin-left:auto;
  margin-right:auto;
}

.content .background {
  /*background-color:var(--page-background-color);*/
  background:var(--page-background-gradient);
  border-radius:5px;
  margin-left:5px;
  margin-right:10px;
}

.item {
  padding:0px;
  margin:0px;
  font-size:0.81em;
  overflow:auto;  
  overflow:hidden;
  position:relative;
  width:100%;
}

.item:not(:first-child) {
  padding-top:30px;
}


.item h3 {
  margin-top:5px;
  margin-bottom:5px;
  padding:10px;
  padding-bottom:5px;
  font-weight:500;
  font-size:1.5em;
  font-weight:600;
  line-height: 1.13em;
  font-family:SegoeUI;
  text-transform:uppercase;
  color:var(--nsys-blue3);
  letter-spacing: 0.02em;
  align-items:center;
  display:flex;
}

.item hr {
  border:0px;
  border-top:1px solid var(--nsys-blue3s);
}

.item span {
  width:100%;
}

.item p.col {
  display:flex;
  flex-direction:row;
  align-items:flex-start;
  padding-left:10px;
}

.item p img {
  margin-left:10px;
  margin-right:20px;
}

.item a {
  color:var(--a-color);
  text-decoration:none;
}

.item a:hover {
  text-decoration:underline;
}

.item hr-custom {
  margin-left:5px;
}

.img_icons {
  width:25px;
  float:left;
  padding-right:10px;
}

@media (max-width: 390px) {
  .item h3 {
    font-size:0.88em;
  }
}

          
`;
