import Dexie from 'dexie';

const CACHE_TIME = 60 * 1000 * 5 ; // 5 minutes
//const CACHE_TIME = 1000;

class TagsDB {
  constructor() {
    this.name = 'tags_public';
    this.dexie = new Dexie(this.name);
    this.loggingEnabled = false;
    this.init();
  }

  log(...messages) {
    if (this.loggingEnabled) {
      console.log(...messages);
    }
  }

  async init() {
    //await this.drop();
    const opts = {};
    opts[this.name] = '_id, name, tcolor, svg, description, fetchDate';
    this.dexie.version(1).stores(opts);
  }

  async loadTag(tagName) {
    const url = `/api/public/meta/tags/name/${tagName}`;
    this.log('loadTag: loading', url);
    const response = await fetch(url);
    const json = await response.json();
    if (json && json.name && json.svg) {

      if (!json.svg.match(/^<svg/)) {
        json.svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="transparent" fill="${json.tcolor}">${json.svg}</svg>`;
      } else {
        // Check if the svg has a class
        //svgClass='flag'
      }

      await this.deleteByTagName(tagName);
      delete json.queryTime;
      json.fetchDate = Date.now();
      await this.dexie[this.name].add(json);
      this.log(`loadTag: tag ${tagName} has been added.`);
    }
    return json;
  }

  async deleteByTagName(tagName) {
    const tag = await this.dexie[this.name].where('name').equals(tagName).first();

    // If the tag exists, delete it
    if (tag) {
      await this.dexie[this.name].delete(tag._id);
      this.log(`deleteByTagName: tag ${tagName} has been deleted.`);
    } else {
      this.log(`deleteByTagName: tag ${tagName} not found.`);
    }
  }

  async getTagByName(tagName) {
    this.log(`getTagByName: looking for tag ${tagName}`);
    let tag = await this.dexie[this.name].where('name').equals(tagName).first();
    if (!tag) {
      this.log(`getTagByName: tag ${tagName} not found in local database`);
      tag = await this.loadTag(tagName);
      return tag;
    }
    
    if (!tag.fetchDate || Date.now() - tag.fetchDate > CACHE_TIME) {
      tag = await this.loadTag(tagName);
      return tag;
    }

    this.log(`getTagByName: tag ${tagName} found`);

    return tag;
  }

  async drop() {
    await this.dexie.delete();
    this.log(`Database ${this.name} has been dropped.`);
  }
}

export default new TagsDB();