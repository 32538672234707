import fetcher from './fetcher.js';

class SessionManager {
  constructor() {
    this.session = null;
  }

  async get() {
    const response = await fetcher.get('public/user/session');
    this.session = response?.data || {};
    return this.session;
  }

}

const sessionManager = new SessionManager();

export default sessionManager;