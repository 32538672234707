import { LitElement, html, css } from 'lit-element';
import { Router } from '@vaadin/router';
import pageStyles from '../../styles/page.js';
import stepsStyles from './styles.js';
import utils from './utils.js';
import Step from './Step.js';
import fetcher from '../../utils/fetcher.js';
import session from '../../utils/session.js';

class Step4 extends Step {
  static get styles() {
    return [
      pageStyles,
      stepsStyles,
      css`
        .align {
          display: flex;
          justify-content: flex-start;
          margin-bottom:10px;
        }

        .align sl-input {
          width:250px;
        }

        @media (max-width: 600px) {
          .align sl-input {
            width:150px;
          }
        }

        .align > * {
          margin-right:10px;
        }

        label {
          font-size:1.1em;
          line-height:2em;
          min-width:120px;
          white-space:nowrap;
          display:inline-block;
        }

        .wish {
          margin:10px;
          font-size:1.1em;
          margin-top:20px;
        }

        m-icon {
          color:var(--sl-color-neutral-400);
          cursor:pointer;
          line-height:30px;
        }

        m-icon:hover {
          color:var(--sl-color-neutral-600);
        }

        .bt_icon::part(base)   {
          height: 30px;
          min-height:initial;
          line-height:initial ;
        }

        .bt_icon::part(label) {
          margin:0px;
          padding:0px;
        }

        #loader {
          text-align:center;
        }

        #loader img {
          animation: rotate 5s linear infinite;
        }

        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        .error_dialog m-icon {
          color:red;
          font-size:50px;
        }

        .modal_dialog::part(header-actions) {
          display:none;
        }

        sl-dialog::part(title) {
          padding:10px;
        }

        sl-dialog::part(body) {
          padding:10px;
          overflow:hidden;
          min-height:140px;
        }

        .center {
          text-align:center;
        }
      `
    ];
  }

  static get properties() {
    return {
      showSubmitDialog: { type: Boolean },
      showSubmitDialogError: { type: Boolean },
      showEmailCheckFailed: { type: Boolean }
    };
  }

  constructor() {
    super();
    this.showSubmitDialog = false;
    this.showSubmitDialogError = false;
    this.showEmailCheckFailed = false;
  }

  async connectedCallback() {
    super.connectedCallback();
    this.who = utils.getUrlParam('who', 'sysdream');
    this.wish = utils.getUrlParam('wish', 'dida');

    if (!utils.isWhoAllowed(this.who)) {
      Router.go('/public');
      return;
    }

    if (!utils.isWishAllowed(this.wish)) {
      Router.go('/public');
      return;
    }

    const sessionData = await session.get();
    if (!sessionData.email || !sessionData.emailCode) {
      Router.go('/public/mondossier/etape3'+window.location.search);
      return;
    }
  }

  firstUpdated() {
    super.firstUpdated();
    this.inputCodeField = this.shadowRoot.querySelector('input-code');
    this.btNext = this.shadowRoot.querySelector('#bt_next');
    this.loader = this.shadowRoot.querySelector('#loader');

    setTimeout(() => {
      this.inputCodeField && this.inputCodeField.setFocus();
    }, 100);
  }

  async handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.showSubmitDialog = true;
    let response;


    try {
      response = await fetcher.post('public/rt/email/check', { code: this.inputCodeField.getValue() })
    } catch(e) {
      console.log(e);
      this.closeDialog();
      this.showSubmitDialogError = true;
      return;
    }

    if (!response) {
      this.closeDialog();
      this.showSubmitDialogError = true;
      return;
    }

    this.closeDialog();

    if (response.ok === false) {
      if (response.error === 'EEMAIL_CHECK_FAILED') {
        this.showEmailCheckFailed = true;
      } else {
        this.showSubmitDialogError = true;
      }
      return;
    }

    Router.go('/public/mondossier/etape5'+window.location.search);
  }

  closeDialog() {
    this.showSubmitDialog = false;
    this.showSubmitDialogError = false;
    this.showForbidden = false;
  }

  handleInputCodeChange(e) {
    if (e.detail.value) {
      this.btNext.disabled = false;
      setTimeout(() => {
        this.btNext.focus();
      }, 1);
    } else {
      this.btNext.disabled = true;
    }
  }

  render() {
    return html`
      <div class="content">
        <div class="background">
          <div class="item">
            <h3><img src="assets/img/dossier.png" class="img_icons"/>${document.title}</h3>
            <hr-custom></hr-custom>
            <!--<div class="wish">${utils.getWishTitle(this.wish)}.</div>-->
            <form @submit="${this.handleSubmit}">
              Un email vient de vous être envoyé, il contient un code a 4 chiffres que vous devez resaisir ou copier coller ci-dessous.<br/><br/>
              <div class="center"><input-code @change="${this.handleInputCodeChange}"></input-code></div>
            </form>
            
            <div class="buttons">
              <sl-button @click="${this.goPreviousStep}" variant="text">
                <m-icon slot="prefix" name="navigate_before"></m-icon>
                  Retour
              </sl-button>
              <sl-button @click="${this.handleSubmit}" variant="primary" disabled id="bt_next">
                <m-icon slot="suffix" name="navigate_next"></m-icon>
                  Suivant
              </sl-button>
            </div>
          </div>
        </div>

        <sl-dialog label="Vérification de votre email en cours .." ?open="${this.showSubmitDialog}" class="modal_dialog">
          <br/><br/>
          <sl-progress-bar indeterminate></sl-progress-bar>
          <br/><br/>
        </sl-dialog>

        <sl-dialog label="Erreur" ?open="${this.showSubmitDialogError}" @sl-after-hide="${this.closeDialog}" class="error_dialog">
          <p class="center">
            <m-icon name="sync_problem"></m-icon><br/><br>
            Une erreur est survenue pendant la vérification de votre dossier.<br/><br/>
            Merci de réessayer ultérieurement.
          </p>
        </sl-dialog>

        <sl-dialog label="Echec" ?open="${this.showEmailCheckFailed}" @sl-after-hide="${this.closeDialog}" class="error_dialog">
          <p class="align">
            <m-icon name="do_not_disturb_on"></m-icon>
            Le code que vous avez saisi est incorrect.
          </p>
        </sl-dialog>
      </div>
    `;
  }

  goPreviousStep() {
    Router.go('/public/mondossier/etape3'+window.location.search);
  }

}

customElements.define('page-dossier-etape4', Step4);
