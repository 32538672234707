import { LitElement, html, css } from 'lit-element';
import pageStyles from '../../styles/page.js';
import { Router } from '@vaadin/router';

class LogoutPage extends LitElement {
  static get styles() {
    return [
      pageStyles,
      css`
        .error_container {
          padding:20px;
        }
      `
    ];
  }

  render() {
    return html`
      <br/>
      <div class="content">
        <div class="item">
          <h3>A bientôt</h3>
          <hr-custom></hr-custom>
          <div class="error_container">
            <p>Vous avez été déconnecté avec succès. </p>
            <br/>
            <sl-button @click="${this.goBack}" size="small">Retourner à l'accueil</sl-button>
          </div>
        </div>
      </div>
    `;
  }

  goBack() {
    Router.go('/');
  }

}

customElements.define('page-user-logout', LogoutPage);