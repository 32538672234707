import { LitElement, html, css } from 'lit-element';
import {unsafeSVG} from 'lit/directives/unsafe-svg.js';
import db from'./sd-tags-db';

const DEFAULT_SVG = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="transparent" fill="white"><path d="M10,19H13V22H10V19M12,2C17.35,2.22 19.68,7.62 16.5,11.67C15.67,12.67 14.33,13.33 13.67,14.17C13,15 13,16 13,17H10C10,15.33 10,13.92 10.67,12.92C11.33,11.92 12.67,11.33 13.5,10.67C15.92,8.43 15.32,5.26 12,5A3,3 0 0,0 9,8H6A6,6 0 0,1 12,2Z" /></svg>';;

class SdTag extends LitElement {
  static get properties() {
    return {
      name: { type: String },
      removable: { type: Boolean },
      size: { type: String },
    };
  }

  static get styles() {
    return css`
      :host {
        position: relative;
        display: flex;
        opacity:0;
        align-items: center;
        vertical-align: middle;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
        border: 1px solid !important;
        border-radius: 3px;
        padding: 0px;
        padding-left: 2px;
        margin-right: 5px;
        margin-top: 1px;
        margin-bottom: 1px;
        height: 19px;
        border: 1px solid var(--tag-border-color) !important;
        border-color: var(--tag-border-color);
        background-color: var(--tag-background-color);
      }

      :host([size="small"]) {
        font-size: 0.75em;
      }

      button {
        background-color: transparent;
      }

      sl-icon-button::part(base) {
        padding: 2px;
        padding-right:4px;
        font-size: 0.8em;
      }

      sl-icon-button::part(base):hover {
        color:red;
      }

      :host([removable]) button {
        visibility: visible;
      }

      .tag-svg {
        width: 14px;
        height: auto;
      }

      .tag-svg svg {
        width:100%;
        height:100%;
        margin-top:4px;
        margin-left:2px;
      }

      .slot-content {
        padding-left: 5px;
        padding-right: 5px;
        font-family: SourceCodePro;
        font-size: 10px !important;
        user-select:none;
        white-space: nowrap;
      }

      .tag-container {
        display:flex;
        width:auto;
        align-items: center;
        justify-content: center;
      }

    `;
  }

  constructor() {
    super();
    this.removable = false;
    this.size = 'medium';
    this.svg = '&nbsp;';
  }

  set name(newValue) {
    const oldValue = this._name;
    this._name = newValue;
    this.requestUpdate('name', oldValue);
  }

  get name() {
    return this._name;
  }

  async connectedCallback() {
    super.connectedCallback();
    if (this.name) {
      await this.loadTag(this.name);
    }
  }

  async loadTag(tagName) {
    let tag = await db.getTagByName(tagName);
    if (!tag) {
      this.svg = DEFAULT_SVG;
    }
    this.style.setProperty('--tag-border-color', `${tag.tcolor}`);
    this.style.setProperty('--tag-background-color', `${tag.tcolor}44`);
    this.svg = tag.svg;
    this.requestUpdate(); // Force a re-render of the component
    this.style.opacity = 1;
    return tag;
  }

  render() {
    return html`
      <div class="tag-container">
        <div class="tag-svg">${unsafeSVG(this.svg)}</div>
        <div class="slot-content"><slot></slot></div>
        ${this.removable ? html`<sl-icon-button part="remove-button" exportparts="base:remove-button__base" name="x-lg" library="system" class="tag__remove" tabindex="-1" label="Remove" @click=${this.handleRemove}></sl-icon-button>` : ''}
      </div>
    `;
  }

  handleRemove(e) {
    e.stopPropagation();
    this.dispatchEvent(new CustomEvent('sl-remove', { bubbles: true, composed: true }));
  }
}

customElements.define('sd-tag', SdTag);