import { LitElement, html, css } from 'lit-element';
import { Router } from '@vaadin/router';
import pageStyles from '../../styles/page.js';
import stepsStyles from './styles.js';
import utils from './utils.js';
import Step from './Step.js';

class Step2 extends Step {
  static get styles() {
    return [
      pageStyles,
      stepsStyles,
      css``
    ];
  }

  static get properties() {
    return {
      selectedRadio: { type: String },
    };
  }

  constructor() {
    super();
    this.defaultRadioValue = 'dida';
    this.selectedWish = this.defaultRadioValue;
    this.selectedWishUrlParam = 'wish';
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('popstate', this.handlePopState.bind(this));

    this.selectedWish = utils.getUrlParam(this.selectedWishUrlParam, this.defaultRadioValue);
    this.selectedWho = utils.getUrlParam('who', 'sysdream');

    if (!utils.isWhoAllowed(this.selectedWho)) {
      Router.go('/public');
      return;
    }

    if (!utils.isWishAllowed(this.selectedWish)) {
      Router.go('/public');
      return;
    }

    utils.setUrlParam(this.selectedWishUrlParam, this.selectedWish);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('popstate', this.handlePopState.bind(this));
  }

  handlePopState() {
    this.selectedWish = utils.getUrlParam(this.selectedWishUrlParam, this.defaultRadioValue);
  }

  handleRadioChange(e) {
    this.selectedWish = e.target.value;
    utils.setUrlParam(this.selectedWishUrlParam, this.selectedWish);
  }

  render() {
    const wishDisabled = ['incass', 'exo'];
    return html`
      <div class="content" radio="${this.selectedWish}">
        <div class="background">
          <div class="item">
            <h3><img src="assets/img/dossier.png" class="img_icons"/>${document.title}</h3>
            <hr-custom></hr-custom>
            <form>
              <sl-radio-group size="small" @sl-change="${this.handleRadioChange}" size="small" value="${this.selectedWish}">
                ${utils.wishes.map(wish => html`<sl-radio .disabled=${wishDisabled.includes(wish.value)} value="${wish.value}">${wish.radioLabel}</sl-radio>`)}
              </sl-radio-group>
            </form>
            
            <div class="buttons">
              <sl-button @click="${this.goPreviousStep}" variant="text">Précédent</sl-button>
              <sl-button @click="${this.goNextStep}" variant="primary" id="bt_next">
                <m-icon slot="suffix" name="navigate_next"></m-icon>
                Suivant
              </sl-button>
            </div>
          </div>
        </div>
      </div>
    `;
  }

  goPreviousStep() {
    Router.go('/public/mondossier/etape1'+window.location.search);
  }

  goNextStep() {
    Router.go('/public/mondossier/etape3'+window.location.search);
  }

}

customElements.define('page-dossier-etape2', Step2);