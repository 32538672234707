import { LitElement, html, css } from 'lit-element';
import { Router } from '@vaadin/router';
import config from './config';
import app from './app';
import 'web-particles';

import './styles/main.scss';
import './pages/errors/404.js'

import '@shoelace-style/shoelace';
import '@shoelace-style/shoelace/dist/themes/light.css';
import '@shoelace-style/shoelace/dist/themes/dark.css';

import './components/index.js';

class MainApp extends LitElement {
  static get styles() {
    return css`
      :host {
        display:block;
      }

      #outlet {
        width:100%;
        overflow:auto;
        min-height:73vh;
        margin-top: 80px;
        position:relative;
      }
    `;
  }

  constructor() {
    super();
    this.reverseAnimation = false;
    this.handleNavigationChange = this.handleNavigationChange.bind(this);
    this.handleWebParticlesToggle = this.handleWebParticlesToggle.bind(this);
  }

  firstUpdated() {
    this.router = app.router = new Router(this.shadowRoot.getElementById('outlet'));
    this.router.setRoutes(config.routes);
    window.addEventListener('vaadin-router-location-changed', this.handleNavigationChange);
    window.addEventListener('web-particles-toggle', this.handleWebParticlesToggle);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('vaadin-router-location-changed', this.handleNavigationChange);
  }

  handleNavigationChange(e) {
    document.title = e.detail.location.route.title;
  }

  handleWebParticlesToggle(e) {
    setTimeout(() => {
      this.webParticles = this.webParticles || this.shadowRoot.querySelector('web-particles canvas');
      if (!this.webParticles) return;
      if (!this.webParticles.style.transition) this.webParticles.style.transition = 'opacity 0.5s ease-in-out';
      if (!e.detail) {
        this.webParticles.style.opacity = this.webParticles.style.opacity !== '1' ? '1' : '0';
      } else {
        this.webParticles.style.opacity = e.detail.show === true ? '1' : '0';
      }
      console.log(this.webParticles.style.opacity);
    }, 200);
  }


  render() {
    return html`
      <app-header></app-header>     
      <web-particles url="assets/webparticles.json"></web-particles>
      <div id="outlet">
      </div>
      <app-footer></app-footer>
    `;
  }
}

customElements.define('main-app', MainApp);
