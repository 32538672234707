class SessionService {
  constructor() {
    this.sessionChecked = false;
    this.sessionInfo = {};
  }

  async getUserSession() {
    if (!this.sessionChecked) {
      try {
        const response = await fetch('/api/v2/public/user/session');
        const json = await response.json();
        if (json) {
          this.sessionInfo = json.data;
          this.sessionChecked = true;
        }
      } catch (error) {
        console.error('Failed to check session:', error);
      }
    }
    return this.sessionInfo;
  }
}

// Créer une instance singleton de SessionService
export const sessionService = new SessionService();
