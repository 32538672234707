import { LitElement } from 'lit-element';
import { SlDivider } from '@shoelace-style/shoelace';
import config from '../../config';
import utils from './utils.js';

class Step extends LitElement {
  constructor() {
    super();
    this.loggingEnabled = false;
  }

  log(...messages) {
    if (this.loggingEnabled) {
      console.log(this.constructor.name, ...messages);
    }
  }

  async connectedCallback() {
    this.log('connectedCallback');
    super.connectedCallback();
    this.isDark = config.isDark;
    window.addEventListener('theme-change', this.handleThemeChange.bind(this));
    utils.handlePageBackground();
  }

  disconnectedCallback() {
    this.log('disconnectedCallback');
    super.disconnectedCallback();
    window.removeEventListener('theme-change', this.handleThemeChange.bind(this));
  }

  async handleThemeChange(e) {
    this.log('handleThemeChange');
    this.isDark = e.detail.isDark;
    await this.requestUpdate();
  }
}

export default Step;