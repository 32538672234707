import { LitElement, html, css } from 'lit-element';
import { Router } from '@vaadin/router';
import pageStyles from '../styles/page.js';
import '../components/ARoute.js';

class PageHome extends LitElement {
  static get styles() {
    return [
      pageStyles,
      css`        
      .imground {
        background-color: white;
        border-radius: 50%;
        width:60px;
        margin-left:5px;
      }

      .center {
        text-align:center;
        padding-top:20px;
        padding-bottom:30px;
      }

      sl-button {
        margin-top:10px;
        min-width:200px;
      }

      sl-button.red {
        float:initial;
      }

      sl-button.big::part(label) {
        line-height:18px;
      }

      .text {
        margin-left:20px;
        margin-right:20px;
      }

      a-route {
        text-decoration-color:var(--sl-color-primary-500);
        line-height:20px;
      }

      .comment {
        text-align:right;
        font-size:0.9em;
        display:inline-block;
      }

      .col-top {
        margin-top:20px;
        display:flex;
      }

      .margin h3 {
        display:block;
        margin-top:0px;
        margin-bottom:0px;
        padding:10px;
        font-weight:500;
        font-size:1.3em;
        font-weight:600;
        line-height: 1.13em;
        font-family:SegoeUI;
        text-transform:uppercase;
        color:var(--nsys-blue3);
        letter-spacing: 0.02em; // Ajustez cette valeur selon vos besoins
      }

      xcenter {
        text-align:center;
        display:block;
        padding-top:5px;
        width:100%;
      }

      `
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    document.body.classList.remove('caf_bg');
  }

  render() {
    return html`
      <div class="content">
        <div class="background">
          <div class="item">
            <h3>
              <img src="assets/img/crise.png" class="img_icons"/>Centre de Lutte Contre la Cybercriminalité SysDream (CLCCS)
            </h3>
            <hr-custom></hr-custom>
            <p class="col">
              
              <span>
                Déclarer ou suivre un incident de sécurité, effectuer une demande d'assistance ou suivre une levée de doute.
                <xcenter>
                  <sl-button @click="${this.goToFolder}" pill variant="primary">
                    <m-icon slot="suffix" name="navigate_next"></m-icon>
                    Acceder à mon dossier
                  </sl-button>
                </xcenter>
              </span>
            </p>
          </div>

          <div class="item">
            <h3>
              <img src="assets/img/services.png" class="img_icons"/>
              Services en ligne
            </h3>
            <hr-custom></hr-custom>
            <p class="col">
              <span>
                Analysez vos <a href="https://helix01fr.sysdream.io/analyse" title="Analyse de fichier" target="helix">fichiers suspicieux</a>.
                <!--Retrouvez également les <a href="https://helix01fr.sysdream.io/publications/advisories" target="helix" title="Les avis du CERT SysDream">avis</a> et <a href="https://helix01fr.sysdream.io/publications/alerts" target="helix" title="Les alertes du CERT SysDream">alertes</a> du CERT SysDream, <a href="https://helix01fr.sysdream.io/cve" target="helix" title="Encyclopédie des vulnérabilités">l'encyclopédie des vulnérabilités</a>. -->
                Retrouvez également <a href="https://helix01fr.sysdream.io/cve" target="helix" title="Encyclopédie des vulnérabilités">l'encyclopédie des vulnérabilités</a> (CVEs).

                <br/><br/>
                <!--
                <sl-button @click="${this.goToServices}" size="small">
                  <m-icon slot="suffix" name="navigate_next"></m-icon>
                  Acceder aux services en ligne
                </sl-button>
                -->
              </span>
            </p>
          </div>

          <div class="item">
            <h3>
              <img src="assets/img/cert.png" class="img_icons"/>
              Les informations du SOC
            </h3>
            <hr-custom></hr-custom>
            <p class="col">
              <span style="text-align:justify">
                <a href="https://www.trusted-introducer.org/directory/teams/cert-sysdream-fr.html" target="_blank"><img class="imground" src="assets/img/accredit-ti-trans.png" style="float:right"/></a>
                Les équipes de réponse aux incidents RIS de SysDream sont spécialisées dans la <b>gestion des risques informatiques</b>.
                Notre équipe d'analystes experiméntés est à votre <b>écoute 24/7j</b> pour qualifier tout <b>incident de sécurité</b> IT 
                et proposer des mesures de <b>réponses adaptées</b>.
                <br/>
                <span class="comment">
                  <a href="#" @click="${this.showSecInfo}">Informations PGP</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </p>
          </div>
        </div>
      </div>
      <sl-dialog label="Informations clé publique PGP SysDream" class="pgp-info-dialog" style="--width: 60vw;">
          <ul>
            <li>Identifiant de la clé : 0x109652FC</li>
            <li>Empreinte de la clé : 527A 82E4 5ECE 100B 569B 2C9E 1C26 0B3F 1096 52FC</li>
            <li><a href="https://sysdream.com/files/cert/cert-sysdream-public_key.asc" target=_"blank">Télécharger la clé publique</a></li>
          </ul>
        <sl-button slot="footer" @click="${this.hideSecInfo}" variante="primary">Fermer</sl-button>
      </sl-dialog>
      
    `;
  }

  showSecInfo(ev) {
    ev.preventDefault();
    this.shadowRoot.querySelector('.pgp-info-dialog').show();
  }

  hideSecInfo() {
    this.shadowRoot.querySelector('.pgp-info-dialog').hide();
  }

  goToServices() {
    Router.go('/public/services');
  }

  goToFolder() {
    Router.go('/public/mondossier/etape1');
  }

}

customElements.define('page-home', PageHome);