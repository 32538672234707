const fetcher = {

  basePath: '/api/v2',

  get:async(url) => {
    if (!url) {
      throw new Error('fetcher: url is missing');
    }

    if (url[0] !== '/') {
      url = fetcher.basePath + '/' + url;
    }

    const response = await fetch(url)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  },

  getToken:async() => {
    const response = await fetch(fetcher.basePath+'/public/token');
    const json = await response.json();
    return json.tok;
  },

  post:async(url, data, form) => {
    if (!url) {
      throw new Error('fetcher: url is missing');
    }

    if (url[0] !== '/') {
      url = fetcher.basePath + '/' + url;
    }

    const token = await fetcher.getToken();
    if (!token) {
      throw new Error('fetcher: could not fetch CSRF token');
    }
    
    data.csrfToken = token;
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    const json = await response.json();

    if (response.status !== 200) {
      if (json.error) {
        throw new Error(json.error);
      } else {
        throw new Error('Unknow error');
      }
    }

    return json;
  }
}

export default fetcher;