import { LitElement, html, css } from 'lit-element';
import pageStyles from '../../styles/page.js';

class Error404 extends LitElement {
  static get styles() {
    return [
      pageStyles,
      css`
        .error_container {
          padding:20px;
        }
      `
    ];
  }

  render() {
    return html`
      <br/>
      <div class="content">
        <div class="item">
          <h3>404 - Page introuvable</h3>
          <hr-custom></hr-custom>
          <div class="error_container">
            <p>La page que vous cherchez n'existe pas ou n'existe plus. </p>
            <br/>
            <sl-button @click="${this.goBack}" size="small">Retour</sl-button>
          </div>
        </div>
      </div>
    `;
  }

  goBack() {
    window.history.back();
  }

}

customElements.define('error-not-found', Error404);