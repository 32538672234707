import { css } from 'lit-element';

export default css`
  .center {
    text-align:center;
  }

  form {
    margin:20px;
    min-height:200px;
  }


  sl-radio::part(base) {
    border-left:2px solid transparent;
    align-items:center;
    width:100%;
    padding-top:20px;
    padding-bottom:20px;
    padding-left:10px;
    padding-right:10px;
  }

  sl-radio::part(label) {
    line-height: 1.25em;
  }

  sl-radio[aria-checked="true"]::part(base) {
    border-left:2px solid var(--sl-color-primary-500);
  }

  .buttons {
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    padding-left:10px;
    padding-right:10px;
    padding-bottom:10px;
    padding-top:0px;
  }

  .buttons sl-button::part(label) {
    padding:0px;
  }

  .buttons sl-button::part(base) {
    padding-inline-start:0px;
    margin-left:0px;
    margin-right:0px;
    padding:0px;
    padding-left:10px;
    padding-right:10px;
    
  }

  sl-dialog::part(title) {
    padding:10px;
  }

  sl-dialog::part(body) {
    padding:10px;
    overflow:hidden;
    min-height:140px;
  }

  .error_dialog m-icon {
    color:red;
    font-size:50px;
  }

  #loader {
    text-align:center;
  }

  #loader img {
    animation: rotate 5s linear infinite;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  sl-skeleton {
    margin-bottom: 1rem;
  }

  sl-skeleton.large {
    height:100px;
  }

  .center {
    text-align:center;
  }

  .modal_dialog::part(header-actions) {
    display:none;
  }

  m-icon {
    cursor:pointer;
    line-height:30px;
  }

  m-icon:hover {
    color:var(--sl-color-neutral-600);
  }

  .bt_icon::part(base)   {
    height: 30px;
    min-height:initial;
    line-height:initial ;
  }

  .bt_icon::part(label) {
    margin:0px;
    padding:0px;
  }

  @media (max-width: 600px) {
    img.img_caf {
      display: none;
    }

    form {
      min-height:45vh;
    }
  }

`;
