import { LitElement, html, css } from 'lit-element';
import { Router } from '@vaadin/router';
import pageStyles from '../../styles/page.js';

class PublicationAvis extends LitElement {
  static get styles() {
    return [
      pageStyles,
      css`
        .page {
          display:flex;
          flex-direction:row;
          height:100%;
          padding-top:20px;
        }

        .content {
          margin-left:10px;
          margin-right:10px;
        }

        .breadcrumb_container {
          display:flex;
          align-items:center;
        }

        m-icon {
          margin-right:0px;
          font-size:20px;
        }

        .filter_tags_container {
          outline:1px solid red;
          width:400px;
          padding:10px;
        }
      `
    ];
  }

  render() {
    return html`
      <div class="page">
        <div class="filter_tags_container">
          <sd-tags-panel url="/api/public/publication/advisories/tags" localStorageKey="tags_panel" @sl-change=${this.onSelectTagsChange}></sd-tags-selector>
        </div>
        <div class="content">
          <div class="item breadcrumb_container">
            <m-icon slot="prefix" name="home"></m-icon>
            <m-icon slot="prefix" name="chevron_right"></m-icon>
            <sl-breadcrumb>  
              <sl-breadcrumb-item class="breadcrumb_item">Publications</sl-breadcrumb-item>
              <sl-breadcrumb-item class="breadcrumb_item">Avis de sécurité SysDream</sl-breadcrumb-item>
            </sl-breadcrumb>
          </div>
          <search-bar type="search" placeholder="Rechercher..."></search-bar>
        </div>
      </div>
    `;
  }
}

customElements.define('page-publication-avis', PublicationAvis);