import { LitElement, html, css } from 'lit-element';

class HrCustom extends LitElement {
  static get properties() {
    return {
      width: { type: String }
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
        width: var(--custom-hr-width, 98%);
      }
      .gradient {
        height: 1px;
        background: linear-gradient(to right, var(--nsys-blue3), var(--nsys-green3));
        width: 98%;
      }

      @media print {         
          .gradient {
            background: initial;
            border-bottom:1px solid var(--nsys-green3);
          }
        }
    `;
  }

  constructor() {
    super();
    this.width = '100%';
  }

  updated(changedProperties) {
    if (changedProperties.has('width')) {
      this.style.setProperty('--custom-hr-width', this.width);
    }
  }

  render() {
    return html`
      <div class="gradient"></div>
    `;
  }
}

customElements.define('hr-custom', HrCustom);
