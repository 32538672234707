import { LitElement, html, css } from 'lit-element';
import { Router } from '@vaadin/router';
import pageStyles from '../styles/page.js';

class PageServices extends LitElement {
  static get styles() {
    return [
      pageStyles,
      css``
    ];
  }

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    //       

    return html`
      <br/>
      <div class="content">
        <div class="item">
          <h3>Services en ligne</h3>
          <hr-custom></hr-custom>
          <p class="col">
            <img src="assets/img/services.png" width="70"/>
            <span>
              Des services en ligne, gratuits, tels que l'analyse de <b>fichiers suspicieux</b>, le <b>partage sécurisé de fichiers</b>,
              l'accès à une <b>encyclopédie des vulnérabilités</b> (CVE), et la diffusion de d'<b>alertes et avis de sécurité</b> de Sysdream sont à votre disposition.
            </span>
          </p>
        </div>

        <div class="item">
          <h3>Analyse des fichiers suspicieux</h3>
          <hr-custom></hr-custom>
          <p class="col">
            <img src="assets/img/files.png" width="70"/>
            <span>
              Fichiers suspicieux ? Email suspect ? Soumettez vos fichiers pour analyse antivirale et plus encore.<br/><br/>
              <sl-button @click="${this.goToFilesAnalyse}" size="small">Acceder au service d'analyse de fichiers</sl-button>
            </span>
          </p>
        </div>
      </div>
    `;
  }

  goBack() {
    window.history.back();
  }

  goToCVE() {
    document.location.href = '/cve';
  }

  goToFilesAnalyse() {
    document.location.href = '/analyse';
  }

  goToPublications() {
    document.location.href = '/publication';
  }

  goToBlog() {
    document.location.href = 'https://sysdream.com/category/blog/';
  }

}

customElements.define('page-services', PageServices);