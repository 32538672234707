import { LitElement, html, css } from 'lit-element';
import { Router } from '@vaadin/router';
import pageStyles from '../styles/page.js';


class PageCert extends LitElement {
  static get styles() {
    return [
      pageStyles,
      css`        
      .imground {
        background-color: white;
        border-radius: 50%;
        width:60px;
        margin-left:5px;
      }
      `
    ];
  }

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    //       

    return html`
      <div class="content">
        <div class="item">
          <h3>Le CERT SysDream</h3>
          <hr-custom></hr-custom>
          <p class="col">            
            <div>
            <img src="assets/img/cert.png" width="60" style="float:left;margin-right:10px;"/>
              Les équipes de réponse aux incidents RIS de SysDream sont spécialisées dans la <b>gestion des risques informatiques</b>.
              Notre équipe d'analystes experiméntés est à votre <b>écoute 24/7j</b> pour qualifier tout <b>incident de sécurité</b> IT 
              et proposer des mesures de <b>réponses adaptées</b>.
              <br/><br/>
              Créée en 2004, la société <a href="https://www.societe.com/societe/sysdream-451676126.html" target="_blank">SYSDREAM</a> SAS (SIREN 451676126), est en activité <b>depuis 20 ans</b>.
              Spécialisée dans le conseil en systèmes et logiciels informatiques, plus particulièrement le domaine de la <b>cyber sécurité</b>, nous excellons autour des domaines ci-dessous :<br/>
              <ul>
                <li>Tests de pénétration et audit technique</li>
                <li>Éducation / Formation (Sécurité offensive et défensive, Forensics et Gouvernance)</li>
                <li>Organisation d'événements de cybersécurité (<a href="https://nuitduhack.com" target="_blank">La Nuit du Hack</a>, <a href="https://lehack.org" target="_blank">leHACK</a>)</li>
                <li>Centre des opérations de sécurité (SOC)</li>
                <li>Computer Emergency Response Team (CERT)</li>
                <li>Réponse aux incidents (CSIRT)</li>
              </ul>
              <sl-button @click="${this.goBack}" size="small">Retour</sl-button>
            </div>
          </p>
        </div>
      </div>
      
      
    `;
  }

  goBack() {
    window.history.back();
  }

}

customElements.define('page-cert', PageCert);