import { LitElement, html, css } from 'lit-element';
import { Router } from '@vaadin/router';
import pageStyles from '../../styles/page.js';
import stepsStyles from './styles.js';
import utils from './utils.js';
import Step from './Step.js';
import fetcher from '../../utils/fetcher.js';

class Step3 extends Step {
  static get styles() {
    return [
      pageStyles,
      stepsStyles,
      css`
        .align {
          display: flex;
          justify-content: flex-start;
          margin-bottom:10px;
        }

        .align sl-input {
          width:250px;
        }

        @media (max-width: 600px) {
          .align sl-input {
            width:150px;
          }
        }

        .align > * {
          margin-right:10px;
        }

        label {
          line-height:2em;
          min-width:120px;
          white-space:nowrap;
          display:inline-block;
        }

        .wish {
        }

        m-icon[name="help"] {
          color:var(--sl-color-neutral-400);
          cursor:pointer;
          line-height:30px;
        }

        m-icon[name="help"]:hover {
          color:var(--sl-color-neutral-600);
        }

        .bt_icon::part(base)   {
          height: 30px;
          min-height:initial;
          line-height:initial ;
        }

        .bt_icon::part(label) {
          margin:0px;
          padding:0px;
        }

        #loader {
          text-align:center;
        }

        #loader img {
          animation: rotate 5s linear infinite;
        }

        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        .error_dialog m-icon {
          color:red;
          font-size:50px;
        }

        .modal_dialog::part(header-actions) {
          display:none;
        }

        sl-dialog::part(title) {
          padding:10px;
        }

        sl-dialog::part(body) {
          padding:10px;
          overflow:hidden;
          min-height:140px;
        }

        .center {
          text-align:center;
        }
      `
    ];
  }

  static get properties() {
    return {
      showTicketDialog: { type: Boolean },
      showEmailDialog: { type: Boolean },
      showSubmitDialog: { type: Boolean },
      showSubmitDialogError: { type: Boolean }
    };
  }

  constructor() {
    super();
    this.showTicketDialog = false;
    this.showEmailDialog = false;
    this.showSubmitDialog = false;
    this.showSubmitDialogError = false;
    this.showForbidden = false;
  }

  async connectedCallback() {
    super.connectedCallback();
    this.who = utils.getUrlParam('who', 'sysdream');
    this.wish = utils.getUrlParam('wish', 'dida');

    if (!utils.isWhoAllowed(this.who)) {
      Router.go('/public');
      return;
    }

    if (!utils.isWishAllowed(this.wish)) {
      Router.go('/public');
      return;
    }
  }

  firstUpdated() {
    super.firstUpdated();
    this.ticketField = this.shadowRoot.querySelector('#ticket');
    this.emailField = this.shadowRoot.querySelector('#email');
    this.loader = this.shadowRoot.querySelector('#loader');
    this.btNext = this.shadowRoot.querySelector('#bt_next');

    setTimeout(() => {
      this.emailField && this.emailField.focus();
    }, 100);
  }

  onInput(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.ticketField.value.length < 3) {
      this.btNext.disabled = true;
      return;
    }

    if (this.emailField.value.length < 3) {
      this.btNext.disabled = true;
      return;
    }

    this.btNext.disabled = false;
  }

  async handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.showSubmitDialog = true;
    let response;

    try {
      response = await fetcher.post('public/rt/ticket/check', {
        ticket: this.ticketField.value,
        email: this.emailField.value
      })
    } catch(e) {
      console.log(e);
      this.closeDialog();
      this.showSubmitDialogError = true;
      return;
    }

    if (!response) {
      this.closeDialog();
      this.showSubmitDialogError = true;
      return;
    }

    this.closeDialog();

    if (!response.ok) {
      if (response.error === 'EEMAIL_INVALID') {
        this.emailField.setCustomValidity('Adresse email invalide');  
        this.emailField.reportValidity();
        this.emailField.focus();
      }

      if (response.error === 'ETICKET_INVALID') {
        this.ticketField.setCustomValidity('Numéro de ticket invalide');  
        this.ticketField.reportValidity();
        this.ticketField.focus();
      }

      if (response.error === 'ETICKET_NOTFOUND') {
        this.ticketField.setCustomValidity('Dossier introuvable, veuillez vérifier le numéro.');  
        this.ticketField.reportValidity();
        this.ticketField.focus();
      }

      if (response.error === 'EFORBIDDEN') {
        this.showForbidden = true;
      }

      return;
    }

    Router.go('/public/mondossier/etape4'+window.location.search);

  }

  showHelpTicket() {
    this.showTicketDialog = true;
  }

  showHelpEmail() {
    this.showEmailDialog = true;
  }

  closeDialog() {
    this.showTicketDialog = false;
    this.showEmailDialog = false;
    this.showSubmitDialog = false;
    this.showSubmitDialogError = false;
    this.showForbidden = false;
  }

  render() {
    return html`
      <div class="content">
        <div class="background">
          <div class="item">
            <h3><img src="assets/img/dossier.png" class="img_icons"/>${document.title}</h3>
            <hr-custom></hr-custom>
            <form @submit="${this.handleSubmit}">
              <div class="wish">${utils.getWishTitle(this.wish)}.</div>
              <br/><br/>
              <div class="align">
                <label>Votre email</label>
                <sl-input size="small" type="email" --width="300px" no-spin-buttons required minlength="3" id="email" @sl-input="${this.onInput}"></sl-input>
                <sl-button @click="${this.showHelpEmail}" variant="text"  size="small" class="bt_icon">
                  <m-icon name="help"></m-icon>
                </sl-button>
              </div>
              <div class="align">
                <label>Numéro de dossier</label>
                <sl-input size="small" type="number" --width="300px" no-spin-buttons required minlength="3" maxlength="5" id="ticket" @sl-input="${this.onInput}"></sl-input>
                <sl-button @click="${this.showHelpTicket}" variant="text" size="small" class="bt_icon">
                  <m-icon name="help"></m-icon>
                </sl-button>
              </div>
            </form>
            
            <div class="buttons">
              <sl-button @click="${this.goPreviousStep}" variant="text">Précédent</sl-button>
              <sl-button @click="${this.handleSubmit}" variant="primary" id="bt_next" disabled>
                <m-icon slot="suffix" name="navigate_next"></m-icon>
                  Suivant
              </sl-button>
            </div>
          </div>
        </div>

        <sl-dialog label="Numéro de dossier" ?open="${this.showTicketDialog}">
          <p>Le numéro de dossier est un nombre d'au moins 3 chiffres. Exemple: 429</p>
          <sl-button slot="footer" @click="${this.closeDialog}">Fermer</sl-button>
        </sl-dialog>

        <sl-dialog label="Votre email" ?open="${this.showEmailDialog}">
          <p>Votre adresse mail doit être inscrite en tant que personne autorisée.</p>
          <sl-button slot="footer" @click="${this.closeDialog}">Fermer</sl-button>
        </sl-dialog>

        <sl-dialog label="Vérification de votre dossier en cours .." ?open="${this.showSubmitDialog}" class="modal_dialog">
          <div id="loader">
            <img src="/assets/img/syrcle.svg" width="100" />
          </div>
          <!-- <sl-button slot="footer" @click="${this.closeDialog}">Fermer</sl-button> -->
        </sl-dialog>

        <sl-dialog label="Erreur" ?open="${this.showSubmitDialogError}" @sl-after-hide="${this.closeDialog}" class="error_dialog">
          <p class="center">
            <m-icon name="sync_problem"></m-icon><br/><br>
            Une erreur est survenue pendant la vérification de votre dossier.<br/><br/>
            Merci de réessayer ultérieurement.
          </p>
        </sl-dialog>

        <sl-dialog label="Accès refusé" ?open="${this.showForbidden}" @sl-after-hide="${this.closeDialog}" class="error_dialog">
          <p class="align">
            <m-icon name="do_not_disturb_on"></m-icon>
            En raison de la stratégie de déclaration d'incident relative au CERT SysDream, 
            merci de bien vouloir prendre contact avec le déclarant originel de l'incident.
          </p>
        </sl-dialog>
      </div>
    `;
  }

  goPreviousStep() {
    Router.go('/public/mondossier/etape2'+window.location.search);
  }

  goNextStep() {
    Router.go('/public/mondossier/etape4'+window.location.search);
  }

}

customElements.define('page-dossier-etape3', Step3);