import { LitElement, html, css } from 'lit-element';
import SdTagSelector from '../sd/sd-tags-selector.latest.js';
import SelectDateRange   from '../SelectDateRange.js';

class SearchBar extends LitElement {
  static get styles() {
    return css`
      :host {
      }

      :host > * {
        margin-bottom:5px;
      }

      form {
        display: flex;
        gap: 0.5rem;
      }

      .search_bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
      }

      .search_bar sl-input {
        flex: 1;
        margin-right: 10px; /* Ajoutez une marge à droite si nécessaire */
      }

      .search_bar m-icon {
        width: 30px;
        flex-shrink: 0; /* Empêche m-icon de rétrécir si l'espace est insuffisant */
        cursor:pointer;
      }

      @media (max-width: 600px) {
        :host {
          flex-direction: column;
        }

        sl-select {
          margin-right: 0;
        }

        sl-select:first-child {
          margin-right: 0;
          margin-bottom: 5px;
          width: 100%;
        }

        sl-select:last-child {
          flex: 1 1 auto;
        }

        form {
          display:block;
        }

        sl-input[slot="suffix"] {
          color: var(--sl-color-white-500);
          width: 23px;
          margin-right: 3px;
        }
      }

    `;
  }

  connectedCallback() {
    super.connectedCallback();
    this.formValues = {};
    this.onSelectDateRangeChange = this.onSelectDateRangeChange.bind(this);    
  }

  firstUpdated() {
    super.firstUpdated();
    setTimeout(() => {
      const i = this.shadowRoot.querySelector('.search_bar sl-input');
      i && i.focus();
    }, 10);
  }

  onSelectDateRangeChange(e,b) {
    this.formValues.period = e.detail.value;
    this.onChange();
  }

  onSelectTagsChange(e) {
    console.log('onSelectTagsChange', e);
    this.formValues.tags = e.target.value;
    this.onChange();
  }

  onChange() {
    const queryString = { };
    const datesRange = this.formValues.period;
    console.log('onChange: queryString', this.formValues);
  }

  render() {
    return html`
      <!--
      <div class="search_bar">
        <sl-input clearable size="small" pill>
          <m-icon slot="suffix" name="search"></m-icon>
        </sl-input>
        <m-icon name="filter_alt" title="Filtres"></m-icon>
      </div>

      <form>
        <select-date-range @sl-change=${this.onSelectDateRangeChange} localStorageKey="search_dates"></select-date-range>
        <sd-tags-selector url="/api/public/publication/advisories/tags" localStorageKey="seach_tags" @sl-change=${this.onSelectTagsChange}></sd-tags-selector>
      </form>
      -->
    `;
  }
}

customElements.define('search-bar', SearchBar);