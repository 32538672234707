import { LitElement, html, css } from 'lit-element';
import { Router } from '@vaadin/router';
import config from '../config';

class ARoute extends LitElement {
  static get styles() {
    return css`
      a {
        color: var(--sl-color-primary-600);
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }

      m-icon {
        font-size: 0.8em; /* Réduire la taille de la police de l'icône */
        vertical-align: middle;
      }
    `
  }

  static get properties() {
    return {
      href: { type: String },
      title: { type: String, reflect: true }
    };
  }

  constructor() {
    super();
    this.href = '';
    this.title = '';
  }

  navigate(e) {
    e.preventDefault();    
    const href = this.href[0] === '/' ? this.href : config.basePath+this.href;
    Router.go(href);
    window.scrollTo(0, 0);
  }

  render() {
    return html`
      <a href="${this.href}" @click="${this.navigate}" aria-label="${this.title}" >
        <!--<m-icon name="info"></m-icon>-->
        <slot></slot>
      </a>
    `;
  }
}

customElements.define('a-route', ARoute);