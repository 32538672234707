import { LitElement, html, css } from 'lit-element';
import { Router } from '@vaadin/router';
import pageStyles from '../styles/page.js';
import '../components/ARoute.js';

class PageNotifications extends LitElement {
  static get styles() {
    return [
      pageStyles,
      css`        
      `
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.activateServiceWorker();
  }

  urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  async activateServiceWorker() {
    const sw = await navigator.serviceWorker.register('/sw.js');
    console.log('activateServiceWorker: service worker', sw);
    this.activateSubscription(sw);
  }

  async activateSubscription(sw) {
    console.log(sw.pushManager);
    //console.log(await sw.getSubscription);
    //console.log(await sw.permissionState);

    const subscription = await sw.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: this.urlBase64ToUint8Array(SDIO_VAPID_PUB_KEY)
    });
    console.log('activateServiceWorker: subscription:', subscription);

    const response = await fetch('/api/v2/push/subscribe', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(subscription)
    });

    if (!response.ok) {
      throw new Error('Erreur lors de l\'envoi de l\'abonnement à /api/push/subscribe');
    }

  }

  async activateNotifications() {
    if (!('Notification' in window)) {
      alert('Votre navigateur ne supporte pas les notifications');
      return;
    }

    if (!navigator.serviceWorker) {
      alert('Votre navigateur ne supporte pas les service workers');
      return;
    }
    
    const result = await Notification.requestPermission();
    if (result !=='granted') {
      alert('Vous avez refusé les notifications');
      return;
    }

    const notification = new Notification('Notifications activées', {
      body: 'Vous recevrez les notifications de la plateforme SysDream',
      data: {},
      icon: 'assets/img/favicon.png',
      //actions: [ { action: 'accept', title: 'Accepter' }, { action: 'refuse', title: 'Refuser' } ]
      //badge: 
      //dir:
      //image:
      //lang:
      //renotify:
      //requireInteraction:
      //silent:
      //tag:'welcome', // same tag, one popup, but body will be updated
      //timestamp:
      //vibrate:

    });

    notification.addEventListener('close', e => {
      console.log('Notification fermée');
    });

    notification.addEventListener('error', e => {
      console.log('Erreur de notificaiton');
    });

    this.activateServiceWorker();

  }

  render() {
    return html`
      <div class="content">
        <div class="item">
          <h3>Notifications</h3>
          <hr-custom></hr-custom>
          <p>
            <sl-button size="small" @click="${this.activateNotifications}">Activer les notifications</sl-button>
          </p>
        </div>
      </div>
    `;
  }
}

customElements.define('page-notifications', PageNotifications);