import { LitElement, html, css } from 'lit-element';
import { Router } from '@vaadin/router';
import pageStyles from '../../styles/page.js';
import stepsStyles from './styles.js';
import utils from './utils.js';
import Step from './Step.js';

const IMG_CAF_BLEU = 'assets/img/cert-aviation-bleu.webp';
const IMG_CAF_WHITE = 'assets/img/cert-aviation-blanc.webp';

class Step1 extends Step {
  static get styles() {
    return [
      pageStyles,
      stepsStyles,
      css`

        sl-radio::part(label) {
          font-size:14px;
        }

        .warning_other {
          display:none;
          padding:10px;
          padding-left:40px;
          font-size:1.1em;
        }

        .warning_other.visible {
          display:block;
        }

        .img_caf {
          position:absolute;
          margin-top:-20px;
          right:10px;
          height:50px;
        }
      `
    ];
  }

  static get properties() {
    return {
      selectedRadio: { type: String },
    };
  }

  constructor() {
    super();
    this.loggingEnabled = false;
    this.defaultRadioValue = 'sysdream';
    this.selectedWhoUrlParam = 'who';
    this.selectedWho = this.defaultRadioValue;
    this.imgCaf = IMG_CAF_WHITE;
  }

  async connectedCallback() {
    super.connectedCallback();
    window.addEventListener('popstate', this.handlePopState.bind(this));
    this.selectedWho = utils.getUrlParam(this.selectedWhoUrlParam, this.defaultRadioValue);
    if (!utils.isWhoAllowed(this.selectedWho)) {
      Router.go('/public');
      return;
    }
    utils.setUrlParam(this.selectedWhoUrlParam, this.selectedWho);
  }

  firstUpdated() {
    this.checkOther();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('popstate', this.handlePopState.bind(this));
  }

  handlePopState() {
    this.selectedWho = utils.getUrlParam(this.selectedWhoUrlParam, this.defaultRadioValue);
  }

  checkOther() {
    /*
    if (this.selectedWho === 'none') {
      this.shadowRoot.querySelector('.warning_other').classList.add('visible');
      this.shadowRoot.querySelector('#bt_next').disabled = true;
    } else {
      this.shadowRoot.querySelector('.warning_other').classList.remove('visible');
      this.shadowRoot.querySelector('#bt_next').disabled = false;
    }
    */
  }

  handleRadioChange(e) {
    this.selectedWho = e.target.value;
    utils.setUrlParam(this.selectedWhoUrlParam, this.selectedWho);
    utils.handlePageBackground();
    this.checkOther();
    this.requestUpdate();
  }

  handleImgCaf() {
    if (this.selectedWho === 'cert-aviation-france') {
      this.imgCaf = this.isDark ? IMG_CAF_WHITE : IMG_CAF_BLEU;
    } else if (this.selectedWho === 'sysdream') {
      this.imgCaf = this.isDark ? IMG_CAF_WHITE : IMG_CAF_BLEU;
    }
  }

  render() {
    this.handleImgCaf();
    return html`
        <div class="content" radio="${this.selectedWho}">
          <div class="background">
            <div class="item">
              <h3><img src="assets/img/dossier.png" class="img_icons"/>${document.title}</h3>
              <hr-custom></hr-custom>
              <form>
                <sl-radio-group size="small" @sl-change="${this.handleRadioChange}" size="small" value="${this.selectedWho}">
                  ${utils.whos.map(who => {
                    if (who.value === 'cert-aviation-france') {
                      return html`<sl-radio value="${who.value}">${who.radioLabel}<img src="${this.imgCaf}" class="img_caf" style="float:right;"/></sl-radio>`
                    } else {
                      return html`<sl-radio value="${who.value}">${who.radioLabel}</sl-radio>`
                    }
                  })}
                </sl-radio-group>
              </form>
              
              <div class="buttons">
                <sl-button @click="${this.goPreviousStep}" variant="text">Retour</sl-button>
                <sl-button @click="${this.goNextStep}" variant="primary" id="bt_next">
                  <m-icon slot="suffix" name="navigate_next"></m-icon>
                  Suivant
                </sl-button>
              </div>
            </div>
          </div>
        </div>
      `
  }

  goPreviousStep() {
    Router.go('/public/');
  }

  goNextStep() {
    Router.go('/public/mondossier/etape2'+window.location.search);
  }

}

customElements.define('page-dossier-etape1', Step1);