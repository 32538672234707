import { LitElement, html, css } from 'lit-element';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { Router } from '@vaadin/router';
import pageStyles from '../../styles/page.js';
import stepsStyles from './styles.js';
import utils from './utils.js';
import Step from './Step.js';
import fetcher from '../../utils/fetcher.js';
import session from '../../utils/session.js';
import app from '../../app.js';
import dayjs from 'dayjs';
import QRCodeStyling from 'qr-code-styling';

class Consulter extends Step {
  static get styles() {
    return [
      pageStyles,
      stepsStyles,
      css`
        .align {
          display: flex;
          justify-content: flex-start;
          margin-bottom:10px;
        }

        .item h3 {
          font-size:1.2em;
        }

        .item h3.title {
          display:flex;
          justify-content:space-between;
          align-items:center;
          margin:10px;
          padding:0px;
          border:0px;          
        }

        .item h3.title div {
          font-size:1.4em;
        }

        .container {
          margin:5px;
        }

        .csirt {
          font-size:2em;
          margin:20px;
          text-align:center;
        }

        .tlp {
          font-size:1.2em !important;
          text-align:center;
          color:#111111;
          padding:5px;
        }

        .tlp.red {
          background-color:#FF2B2B;
        }

        .tlp.amber {
          background-color:#FFC000;
        }

        .tlp.green {
          background-color:#33FF00;
        }

        .tlp.clear {
          background-color:#FFFFFF;
        }

        .ticket_number {
          text-align:center;
          font-size:1.5em !important;
          font-weight:600;
          text-transform:uppercase;
          color:var(--nsys-blue3);
        }

        .margin {
          margin-top:20px;
          margin-bottom:20px;
          margin-left:0px;
          margin-right:0px;
        }

        .title {
          padding:10px;
          font-size:1.3em;
          font-weight:600;
          border:1px solid rgb(159, 158, 158);
        }

        .title.green {
          background-color:var(--nsys-green3);
        }

        .title.lightblue {
          background-color:rgb(148, 212, 210);
        }

        .title.blue {
          background-color:rgb(30, 195, 241);
        }

        .title.gray {
          background-color:rgb(214, 210, 209);
        }

        .line {
          display:flex;
          justify-content:space-between;
          margin-left:10px;
          margin-right:10px;
        }

        .line div {
          width:100%;
        }

        .line .bold {
          font-weight:bold;
        }

        .bold.red {
          color:red;
        }

        .flex {
          display:flex;
          justify-content:space-between;
          gap:20px;
        }

        .flex2 {
          display:flex;
          justify-content:space-between;
          gap:20px;
        }

        .img_icons {
          width:25px;
          padding-right:10px;
        }

        div svg path {
          stroke: #000000;
          stroke-width: 3px;
        }

        .text {
          line-height:1.2em;
          margin-left:10px;
          text-align:justify;
        }

        .header {
          display:flex;
        }

        .qrcode {
          float:right;
          margin-left:10px;
        }

        @media print {         
          .noprint {
            display:none;
          }

          .tlp.red {
            -webkit-print-color-adjust: exact;
            color-adjust: exact;
            background-color: #FF2B2B !important;
          }
        }

        @media (min-width: 600px) {
          .ticket_number {
            font-size:1.8em !important;
            line-height:30px;
          }
        }

      `
    ];
  }

  static get properties() {
    return {
      showLoadingDialogError: { type: Boolean },
      showLoadingDialog: { type: Boolean },
      ticketLoaded: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.showLoadingDialog = false;
    this.showLoadingDialogError = false;
    this.ticketLoaded = false;
  }

  async connectedCallback() {
    super.connectedCallback();
    this.who = utils.getUrlParam('who', 'sysdream');
  }

  async firstUpdated() {
    super.firstUpdated();

    const dialog = this.shadowRoot.querySelector('#loading_dialog');
    if (dialog) {
      dialog.addEventListener('sl-request-close', event => {
        if (event.detail.source === 'overlay') {
          event.preventDefault();
        }
      });
    }

    const hash = app.router.location.params.hash;
    let response, csrfToken;
    this.showLoadingDialog = true;
    try {
      csrfToken = await fetcher.getToken();
      response = await fetcher.post('public/rt/ticket/view', { hash, csrfToken });
    } catch(e) {
      this.showLoadingDialogError = true;
      return;
    }

    if (!response) {
      this.showLoadingDialogError = true;
      return;
    }

    if (response.error === 'EHASH_INVALID') {
      this.shadowRoot.querySelector('.container').innerHTML = 'Le dossier demandé est introuvable.';
    }

    if (response.error === 'ETICKET_NOTFOUND') {
      this.shadowRoot.querySelector('.container').innerHTML = 'Le dossier demandé est introuvable.';
    }

    this.ticket = response.data;
    this.ticketLoaded = true;
    this.showLoadingDialog = false;
    /*
    console.log(response.data);
    console.log(response.data2);
    for (const customField of response.data2.CustomFields) {
      console.log(customField.id, customField.name, customField.values);
    }
    */

    if (this.ticket) {
      setTimeout(async () => {
        document.title = `Dossier n°${this.ticket.id} - CSIRT SysDream`;
        const img = this.shadowRoot.querySelector('.qrcode img');
        if (!img) return;
        img.src = await this.getQrCode();
      }, 100);
    }
  }

  closeDialog() {
    this.showLoadingDialogError = false;
    Router.go('/public');
  }

  formatDate(v) {
    if (!v) return 'N/A';
    return dayjs(v).format('DD/MM/YYYY HH:mm');
  }

  getAnalysteInfo(field) {
    if (!this.ticket) return 'N/A';
    if (!this.ticket.history) return 'N/A';
    if (!this.ticket.history[0]) return 'N/A';
    return this.ticket.history[0][field] || 'N/A';
  }

  getHeaderImage() {
    if (this.who === 'cert-aviation-france') {
      if (document.documentElement.className.match(/dark/)) {
        return html`<img src="assets/img/cert-aviation-blanc.webp" width="150" style="margin-right:20px"/>`;
      } else {
        return html`<img src="assets/img/cert-aviation-bleu.webp" width="150" style="margin-right:20px"/>`;
      }
    }
    return '';
  }

  async readBlobAsDataURL(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => { resolve(reader.result); };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  async getQrCode(opts = { size: 160 }) {
    const permalink = document.location.href;
    
    const qrCode = new QRCodeStyling({
      width:opts.size,
      height:opts.size,
      margin:0,
      type: 'svg',
      data: permalink,
      //image: '/assets/img/syrcle.svg',
      dotsOptions: {
        color: '#1F1F1F',
        type:'rounded'
        //type: 'dots'
      },
      backgroundOptions: {
        color: '#FFFFFF',
      },
      imageOptions: {
        margin: 0,
        //imageSize:0.4
      }
    });

    const blob = await qrCode.getRawData();
    const dataUri = await this.readBlobAsDataURL(blob);
    return dataUri;
  }

  render() {
    return html`
      ${this.ticket ? html`<div class="content">
        <div class="background">
          <div class="item">
            <h3 class="title">
              ${this.getHeaderImage()}
              <div class="title" style="text-align:right;width:100%;border:0px;">CSIRT SysDream</div>
              <div class="tlp red">TLP:RED</div>
            </h3>
            <hr-custom></hr-custom>
            <div class="container">
            ${typeof this.ticket === 'object' ? 
            html`          
              <div class="margin text">
                <div class="ticket_number">Déclaration d'incident n°${this.ticket.id}</div>
              </div>
              <div class="margin flex2">
                <div style="width:100%">
                  <div class="qrcode"><img/></div>
                  <div class="line"><div>Status :</div><div class="bold">${this.ticket.statusText}</div></div>
                  <div class="line"><div>Dernière mise à jour :</div><div class="bold">${this.formatDate(this.ticket.lastUpdated)}</div></div>
                  <div class="line"><div>Séverité: </div><div class="bold">${this.ticket.severityText}</div></div>
                  <div class="text" style="margin-top:10px">
                    Ce rapport est destiné uniquement aux personnes concernées.
                    Celui-ci a pour objectif de suivre le traitement de votre demande d'assistance effectuée auprès de notre service.
                    Il vous permet de connaitre l'état d'avancement des opérations.
                    Nous vous recommandons d'en effectuer une copie papier afin de le remettre aux autorités compétentes.
                  </div>
                </div>
              </div>
              <div class="margin">
                <h3><img src="assets/img/services.png" class="img_icons"/>Informations du déclarant</h3>
                <hr-custom></hr-custom>
                <br/>
                <div class="line"><div>Organisme d'affiliation :</div><div class="bold">${this.ticket.affiliate}</div></div>
                <div class="line"><div>Numéro d'adhérent :</div><div class="bold">${this.ticket.clientId || 'N/A'}</div></div>
                <div class="line"><div>Société :</div><div class="bold">${this.ticket.company}</div></div>
                <div class="line"><div>Nom / Prénom :</div><div class="bold">${this.ticket.declarant}</div></div>
                <div class="line"><div>Adresse email :</div><div class="bold">${this.ticket.declarantEmail}</div>
                </div><div class="line"><div>Numéro de téléphone :</div><div class="bold">${this.ticket.declarantPhone}</div></div>
              </div>
              <div class="margin">
                <h3><img src="assets/img/declaration.png" class="img_icons"/>Informations concernant la déclaration</h3>
                <hr-custom></hr-custom>
                <div class="margin text">${unsafeHTML(this.ticket.subjectText)}</div>
                <div class="margin text">${unsafeHTML(this.ticket.incidentInfosText.join('<br/>'))}</div>
              </div>
              <div class="margin">
                <h3><img src="assets/img/horodatage1.svg" class="img_icons"/>Horodatage</h3>
                <hr-custom></hr-custom>
                <br/>
                <div class="line"><div>Date et heure de la déclaration :</div><div class="bold">${this.formatDate(this.ticket.created)}</div></div>
                <div class="line"><div>Date et heure de prise en charge :</div><div class="bold">${this.formatDate(this.ticket.started)}</div></div>
                <div class="line"><div>Dernière mise à jour :</div><div class="bold">${this.formatDate(this.ticket.lastUpdated)}</div></div>
                <div class="line"><div>Date et heure de la clôture de l'incident :</div><div class="bold">${this.formatDate(this.ticket.resolved)}</div></div>
              </div>
              <div class="margin">
                <h3><img src="assets/img/analyste.png" class="img_icons"/>Analyste du CERT/CSIRT en charge de l'incident</h3>
                <hr-custom></hr-custom>
                <br/>
                <div class="line"><div>Analyste :</div><div class="bold">${this.getAnalysteInfo('name')}</div></div>
                <div class="line"><div>Adresse email :</div><div class="bold">${this.getAnalysteInfo('email')}</div></div>
                <div class="line"><div>Numéro de téléphone :</div><div class="bold">${this.getAnalysteInfo('phone')}</div></div>
              </div>
              <div class="margin">
                <h3><img src="assets/img/evaluation.png" class="img_icons"/>Evaluation de l'incident</h3>
                <hr-custom></hr-custom>
                <br/>
                <div class="line"><div>Sévérité :</div><div class="bold">${this.ticket.severityText}</div></div>
                <div class="line"><div>Classification :</div><div class="bold">${this.ticket.classificationText}</div></div>
                <div class="line"><div>Sous Classification :</div><div class="bold">${this.ticket.subClassificationText}</div></div>
              </div>
              <div class="margin">
                <h3><img src="assets/img/description.png" class="img_icons"/>Description de l'incident</h3>
                <hr-custom></hr-custom>
                <div class="margin text">${unsafeHTML(this.ticket.incidentResumeText.join('<br/>'))}</div>
              </div>
              <div class="margin">
                <h3><img src="assets/img/impact.png" class="img_icons"/>Impact sur le service et les données du déclarant</h3>
                <hr-custom></hr-custom>
                <br/>
                <div class="line"><div>Impact sur des données à caractère personnel :</div><div class="bold">${this.ticket.impactPersonalText}</div></div>
                <div class="line"><div>Impact sur des données de production :</div><div class="bold">${this.ticket.impactProdText}</div></div>
                <div class="line"><div>Production arrêtée :</div><div class="bold">${this.ticket.impactProdStopText}</div></div>
              </div>
              <div class="margin">
                <h3><img src="assets/img/resultat.png" class="img_icons"/>Analyse(s) et résultat(s) effectué(s) par le CSIRT SysDream</h3>
                <hr-custom></hr-custom>
                <div class="margin text"><br/>${unsafeHTML(this.ticket.analyseResultText.join('<br/>'))}<br/><br/></div>
              </div>
              <div class="margin">
                <h3><img src="assets/img/contremesure.png" class="img_icons"/>Contre-mesures pour le déclarant</h3>
                <hr-custom></hr-custom>
                <div class="margin text"><br/>${unsafeHTML(this.ticket.counterMeasureText.join('<br/>'))}<br/><br/></div>
              </div>
              <div class="margin noprint">
                <h3><img src="assets/img/complementaire.png" class="img_icons"/>Informations complémentaires</h3>
                <hr-custom></hr-custom>
                <div class="margin text flex">
                  <div>
                    <img src="/assets/logos/minint.png" width="86"/>
                  </div>
                  <div>
                    Conformément à l'article 5 de <a href="https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000047046768" target="_blank">la loi du 24 janvier 2023 d'orientation et de programmation du ministère de
                    l'Intérieur (LOPMI)</a> qui oblige, depuis le 24 avril 2023, tous les professionnels à effectuer un dépôt de plainte dans un délai de 72 heures
                    suivants la connaissance d’une cyberattaque pour pouvoir être indemnisé par leur assureur, sous réserve que leur contrat le prévoit.
                    <br/><br/>
                    <sl-button @click="${this.goToPreplainte}" pill variant="primary">
                      <m-icon slot="suffix" name="navigate_next"></m-icon>
                      Pré-remplir mon dépot de plainte
                    </sl-button>
                  </div>
                </div>
                <br/>
                <div class="margin text flex">
                  <div>
                    <img src="/assets/logos/cnil.jpg" width="86"/>
                  </div>
                  <div>
                    Si des données personnelles d’utilisateurs ont été volées, une déclaration auprès de la CNIL est obligatoire dans les 72 heures qui suivent la cyberattaque. 
                    <br/><br/>
                    <sl-button @click="${this.goToCnil}" pill variant="primary">
                      <m-icon slot="suffix" name="navigate_next"></m-icon>
                      Notifier une violation de données personnelles
                    </sl-button>
                  </div>
                </div>
              </div>
            ` :
            html`
                <sl-skeleton></sl-skeleton>
                <br/>
                <sl-skeleton></sl-skeleton>
                <sl-skeleton class="large"></sl-skeleton>
            `}
            </div>
          </div>
        </div>
      </div>`: ''}

      <sl-dialog label="Erreur" ?open="${this.showLoadingDialogError}" @sl-after-hide="${this.closeDialog}" class="error_dialog">
        <p class="center">
          <m-icon name="sync_problem"></m-icon><br/><br>
          Une erreur inattendue s'est produite, merci de réessayer ultérieurement.
        </p>
      </sl-dialog>

      <sl-dialog label="Chargement de votre dossier en cours .." ?open="${this.showLoadingDialog}" id="loading_dialog" class="modal_dialog">
        <br/><br/>
        <sl-progress-bar indeterminate></sl-progress-bar>
        <br/><br/>
      </sl-dialog>

    `;
  }

  goPreviousStep() {
    Router.go('/public/mondossier/etape3'+window.location.search);
  }

  goNextStep() {
    Router.go('/public/mondossier/etape5');
  }

  goToPreplainte() {
    window.open('https://www.pre-plainte-en-ligne.gouv.fr/', '_blank');
  }

  goToCnil() {
    window.open('https://notifications.cnil.fr/notifications/index', '_blank');
  }

}

customElements.define('page-dossier-consulter', Consulter);

